import { CalendarPickerView } from '@mui/x-date-pickers';

/**
 * 各画面のパス名
 */
export const PAGE_PATH_NAME = {
  // Dashboard
  DASHBOARD: '/dashboard',
  // ［ログイン］画面
  LOGON: '/login',
  // Groups
  GROUPS: '/groups',
  // Groups
  GROUP_DETAIL: `/groups/groupdetail/:groupId`,
  GROUP_DETAIL_UNGROUP: '/groups/groupdetail/0',
  // Add Group
  ADD_GROUP: '/groups/newgroup',
  // Remote Setting
  REMOTE_SETTINGS: '/remote',
  // Device
  DEVICE: '/device',
  // ［アカウント登録］画面
  ACCOUNT: '/account',
  // ［アカウント登録完了］画面
  ACCOUNT_RRGISTED: '/accountregisted',
  // ［端末一覧］画面
  DEVICE_LIST: '/devicelist',
  // ［端末詳細］画面
  DEVICE_DETAIL: '/devicedetail',
  // ［翻訳回数］画面
  TRANS_COUNT_LIST: '/transcountlist',
  // ［翻訳元言語］画面
  FROM_LANG_LIST: '/fromlanglist',
  // ［翻訳後言語］画面
  TO_LANG_LIST: '/tolanglist',
  // ［パスワード再設定メール送信］画面
  PASSWORD_RESET_SEND_MAIL: '/passwordset/sendmail',
  // ［パスワード再設定メール送信完了］画面
  PASSWORD_RESET_SEND_MAIL_COMPLETE: '/passwordset/sendmail/complete',
  // [パスワード設定]画面
  PASSWORD_SET: '/newuserpasswordset',
  // [パスワード設定完了]画面
  PASSWORD_SET_COMPLETE: '/passwordset/complete',
  // [設定]画面
  SETTING_INFO: '/settinginfo',
  // [翻訳履歴]画面
  TRANS_HISTORY_LIST: '/transhistorylist',
  // [端末登録解除]画面
  DEVICE_DEREGISTER: '/devicedereginter',
  // [端末登録解除完了]画面
  DEVICE_DEREGISTER_COMPLETE: '/devicedereginter/complete',
  // [ユーザー管理]画面
  USER_LIST: '/user',
  // [ユーザー詳細]画面
  USER_DETAIL: '/user/userdetail',
  // [ユーザー招待メール送信]画面
  USER_ADD: '/user/add',
  DEVICE_DETAILS: `/devicelist/devicedetails`,
  PERSONAL_SETTING: '/personalsetting',
  ACCOUNT_RRGISTED_SEND_MAIL: '/accountregisted/sendmail',
  ACCOUNT_RRGISTED_SEND_MAIL_COMPLETE: '/accountregisted/sendmail/complete',
  PASSWORD_RESET: '/passwordreset',
  PASSWORD_RESET_COMPLETE: '/passwordreset/complete',
  CORPORATE_PROFILE: '/corporateprofile',
  SECURITY_SETTINGS: '/securitysettings',
} as const;

/**
 * APIディレクトリパス
 */
export const API_DIRECTORY_PATH = '/iotconsoleapi-co/api';
export const PRIVATE = 'private';
export const PRIVATE_AUTH = 'private-auth';

/**
 * 各APIのURL
 */
export const API_URL = {
  // ログインAPI
  LOGIN_API: `${API_DIRECTORY_PATH}/authenticate`,
  // LogOut
  LOGOUT_API: `${API_DIRECTORY_PATH}/logout`,
  // ワンタイムキー有効確認API
  ONETIME_CHECK_API: `${API_DIRECTORY_PATH}/pub/check-one-time-key`,
  // アカウント登録API
  ACCOUNT_RRGISTER_API: `${API_DIRECTORY_PATH}/pub/register-account`,
  // 端末情報一覧取得API
  DEVICE_LIST_API: `${API_DIRECTORY_PATH}/private/device-list`,
  // SIM情報取得API
  FETCH_SIM_INFO_API: `${API_DIRECTORY_PATH}/private/fetch-sim-info`,
  // ログデータダウンロードAPI
  LOG_DATA_DOWNLOAD_API: `${API_DIRECTORY_PATH}/${PRIVATE}/logdatadownload`,
  // 端末情報取得API
  DEVICE_INFO_API: `${API_DIRECTORY_PATH}/private/device-info`,
  // 端末情報更新API
  DEVICE_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-device-info`,
  // Lost Mode PIN Update
  DEVICE_UPDATE_PIN_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-lost-mode-pin`,
  // 翻訳回数取得API
  TRANS_COUNT_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE}/translation-count-list`,
  // 翻訳回数ダウンロードAPI
  TRANS_COUNT_LIST_DOWNLOAD_API: `${API_DIRECTORY_PATH}/${PRIVATE}/translation-count-list-dl`,
  // 翻訳元言語取得API
  FROM_LANG_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE}/from-lang-list`,
  // 翻訳元言語ダウンロードAPI
  FROM_LANG_LIST_DOWNLOAD_API: `${API_DIRECTORY_PATH}/${PRIVATE}/from-lang-list-download`,
  // 翻訳後言語取得API
  TO_LANG_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE}/to-lang-list`,
  // 翻訳後言語ダウンロードAPI
  TO_LANG_LIST_DOWNLOAD_API: `${API_DIRECTORY_PATH}/${PRIVATE}/to-lang-list-download`,
  // パスワードリマインダーメール送信API(未ログイン)
  PASSWORD_REMINDER_SEND_MAIL_API: `${API_DIRECTORY_PATH}/pub/send-password-reminder-mail`,
  // パスワードリマインダーメール送信API(ログイン)
  PASSWORD_REMINDER_SEND_MAIL_AUTH_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/send-password-reminder-mail`,
  // パスワードリセットAPI
  PASSWORD_RESET_API: `${API_DIRECTORY_PATH}/pub/reset-password`,
  // New User Set password
  PASSWORD_SET_API: `${API_DIRECTORY_PATH}/pub/set-password`,
  // 設定情報取得API
  SETTING_INFO_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/settinginfo`,
  // 設定情報更新API
  SETTING_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/settingupdate`,
  // 端末登録解除API
  DEVICE_DEREGISTER_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/deregister-device`,
  // 翻訳履歴取得API
  TRANS_HISTORY_LIST_API: `${API_DIRECTORY_PATH}/private/translation-history-list`,
  // 翻訳履歴ダウンロードAPI
  TRANS_HISTORY_LIST_DOWNLOAD_API: `${API_DIRECTORY_PATH}/${PRIVATE}/translation-history-list-dl`,
  // ユーザー一覧取得API
  USER_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/user-list`,
  // ユーザー取得API
  USER_INFO_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/user-info`,
  // ユーザー更新API
  USER_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-user`,
  // ユーザー削除API
  USER_DELETE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/delete-user`,
  // ユーザー仮登録API
  USER_PREADD_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/pre-add-user`,
  // 権限一覧取得API
  AUTH_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE}/auth-list`,
  LANGUAGE_LIST_API: `${API_DIRECTORY_PATH}/pub/locale-list`,
  REGISTRATION_INVITATION_API: `${API_DIRECTORY_PATH}/pub/send-mail-invitation`,
  FORGOT_PASSWORD_SEND_API: `${API_DIRECTORY_PATH}/pub/forgot-password-send-mail`,

  DEVICE_LOST_MODE_ENABLE: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/enable-lost-mode`,
  DEVICE_LOST_MODE_DISABLE: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/disable-lost-mode`,

  GROUP_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-list`,
  GROUP_ADD_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/create-group`,
  GROUP_NAME_EDIT_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/edit-group-name`,
  GROUP_DELETE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/delete-group`,
  MANAGE_GROUP_USER_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-modal-users-list`,
  MANAGE_UNGROUP_USER_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/ungrouped-modal-users-list`,
  MANAGE_GROUP_DEVICE_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-modal-devices-list`,
  MANAGE_UNGROUP_DEVICE_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/ungrouped-modal-devices-list`,
  MANAGE_GROUP_USER_LIST_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-group-users`,
  MANAGE_GROUP_DEVICE_N_USER_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-group-users-devices`,
  GROUP_LAST_USER_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/grouplastusers`,
  GROUP_DETAIL_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-details`,
  UNGROUP_USER_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-users-list`,
  GROUP_TOP_USER_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/max-five-group-users`,
  GROUP_DEVICE_LIST_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/group-devices-list`,
  GET_LOST_MODE_PIN_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/get-lost-mode-pin`,
  REMOTE_FEATURE_SETUP_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/setup-remote-device`,
  REMOTE_FEATURE_FACTORY_RESET_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/remote-restore-factory`,
  REMOTE_FEATURE_LIST_GET_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/remote-device-feature-limits`,
  REMOTE_DEVICE_SETUP_INFO_GET_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/setup-remote-device`,
  MANAGE_GROUP_DEVICE_LIST_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-group-devices`,
  GROUP_CHECK_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/check-group`,
  DEVICE_GROUP_LIST: `${API_DIRECTORY_PATH}/${PRIVATE}/account-group-list`,
  UPDATE_PERSONAL_SETTINGS: `${API_DIRECTORY_PATH}/${PRIVATE}/my-page-user-update`,
  FETCH_PERSONAL_SETTINGS: `${API_DIRECTORY_PATH}/${PRIVATE}/my-page-user-info`,
  RE_INVITE_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/re-invite-user`,
  EULA__URL_LINK_API: `${API_DIRECTORY_PATH}/pub/eula-contents`,
  EULA_UPDATE_API: `${API_DIRECTORY_PATH}/${PRIVATE}/consent-eula`,
  REFRESH_TOKEN: `${API_DIRECTORY_PATH}/refresh`,
  GET_CORPORATE_DETAILS: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/get-corporate-contact`,
  UPDATE_CORPORATE_DETAILS: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/update-corporate-contact`,
  DASHBOARD_REMOTE_SETTING: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-remote-setting-info`,
  DASHBOARD_DEVICE_INFO: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-device-info`,
  DASHBOARD_TOTAL_TRANSLATIONS: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-total-translation-info`,
  DASHBOARD_AVERAGE_TRANSLATIONS: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-average-translation-info`,
  DASHBOARD_NUMBER_OF_TRANSLATIONS: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-no-of-translation-info`,
  DASHBOARD_LANGUAGE_FROM_TRANSLATIONS: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-from-lang-translation-info`,
  DASHBOARD_LANGUAGE_TO_TRANSLATIONS: `${API_DIRECTORY_PATH}/${PRIVATE}/dashboard-to-lang-translation-info`,
  NOTIFICATION_GET: `${API_DIRECTORY_PATH}/${PRIVATE}/get-notifications`,
  NOTIFICATION_READ_API: `${API_DIRECTORY_PATH}/${PRIVATE}/update-notification`,
  GET_TRANS_HISTORY_PREVENTION_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/get-trans-history-prevention`,
  CHANGE_TRANS_HISTORY_PREVENTION_API: `${API_DIRECTORY_PATH}/${PRIVATE_AUTH}/change-trans-history-prevention`,
  UPDATE_USER_INFO_API: `${API_DIRECTORY_PATH}/${PRIVATE}/get-updated-user-info`,
} as const;

/**
 * APIリクエスト送信時に指定するリクエストヘッダ Key名
 */
export const REQUEST_HEADER_KEY = {
  // X-IOT-Access-Key
  ACCESS_KEY: 'X-IOT-Access-Key',
  XSRF_TOKEN: 'X-XSRF-TOKEN',
} as const;

/**
 * URLパラメータ key名
 */
export const URL_PARAMETER_KEY = {
  // ワンタイムキー
  onetimekey: 'onetimekey',
  // ロケール(言語)
  locale: 'locale',
  // IMEI
  imei: 'imei',
} as const;

/**
 * CSVダウンロード URLパラメータ key名
 */
export const CSV_DOWNLOAD_KEY = {
  accesskey: 'accesskey',
  corpId: 'corp_id',
  from: 'from',
  to: 'to',
  view: 'view',
  imei: 'imei',
} as const;

/**
 * WEBストレージ
 */
export const WEB_STORAGE = {
  // セッションストレージに保存するアクセスキーのkey名
  IOT_ACCESS_KEY: 'iotAccessKey',
  // ログイン後のアカウント情報
  ACCOUNT_INFO: 'accountInfo',
  // ページネーション復元に必要な情報
  PAGE_RESTORE_STATE: 'pageRestoreState',
  // 端末情報の更新状態
  DEVICE_DETAIL_UPDATE_STATE: 'deviceDetailUpdateState',
  SNACKBAR_STATE: 'snackbarState',
  REDIRECT_DIALOG_STATE: 'redirectDialogSate',
  WELCOME_DIALOG_STATE: 'welcomeDialogState',
  GROUP_LIST_STATE: 'groupListState',
  DEVICE_STATE: 'deviceListState',
  DRAWER_STATE: 'drawerState',
} as const;

/**
 * アクション名
 */
export const ACCTION_NAMR = {
  // ログインAPI呼び出し
  CALL_LOGIN_API: 'login/auth',
} as const;

/**
 * APIリクエスト設定
 */
export const API_REQUEST_CONFIG = {
  // Timeout time at the time of API request (milliseconds)
  DEFAULT_TIMEOUT: 60000 * 3, // 3 min
  // CSVダウンロード関連のAPIに指定するタイムアウト時間
  CSV_DOWNLOAD_TIMEOUT: 3000000,
} as const;

/**
 * 日付フォーマット(yyyy/MM形式)(言語関係なく統一)
 */
export const DATE_FORMAT = 'yyyy/MM' as const;

/**
 * 日付フォーマット(yyyy/MM/dd形式)(言語関係なく統一)
 */
export const DATE_FORMAT_YYYYMMDD = 'yyyy/MM/dd' as const;

/**
 * 日付マスク(yyyy/MM形式)(言語関係なく統一)
 */
export const DATE_MASK = '____/__' as const;

/**
 * 日付マスク(yyyy/MM/dd形式)(言語関係なく統一)
 */
export const DATE_MASK_YYYYMMDD = '____/__/__' as const;

/**
 * 日付形式チェック用の正規表現(yyyy/MM形式)(言語関係なく統一)
 */
export const DATE_REGEX_YYYYMMDD =
  '^[0-9]{4}/(0[1-9]|1[0-2])/(0[1-9]|[12][0-9]|3[01])$' as const;

/**
 * DatePickerを開いた際の初期値
 */
export const DATEPICKER_VIEW_OPEN: CalendarPickerView = 'month' as const;

/**
 * DatePickerを開いた際の初期値
 */
export const DATEPICKER_VIEW_OPEN_DAY: CalendarPickerView = 'day' as const;

/**
 * DatePickerビューの表示内容(年、月のみ)(配列の左から順に表示される)
 * ※as const付けない(「型 'readonly ["year", "month"]' は 'readonly' であるため、変更可能な型 'CalendarPickerView[]' に代入することはできません。」エラーになるため)
 */
export const DATEPICKER_VIEW_CONTENTS: CalendarPickerView[] = ['year', 'month'];

/**
 * DatePickerビューの表示内容(年、月、日)(配列の左から順に表示される)
 * ※as const付けない(「型 'readonly ["year", "month"]' は 'readonly' であるため、変更可能な型 'CalendarPickerView[]' に代入することはできません。」エラーになるため)
 */
export const DATEPICKER_VIEW_CONTENTS_ALL: CalendarPickerView[] = [
  'year',
  'month',
  'day',
];

/**
 * テーブル表示件数リスト
 */
export const TABLE_ROW_NUMBER_PER_PAGE = [30, 50, 100, 200];

/**
 * 本システムで許可するメールアドレス形式
 */
export const EMAIL_CHECK_PATTERN =
  /^[ ]*(?=.{1,64}@)[a-zA-Z0-9]+([!#$%&'*+\-/=?^_`{|}~][a-zA-Z0-9]+)*(\.[a-zA-Z0-9]+([!#$%&'*+\-/=?^_`{|}~][a-zA-Z0-9]+)*)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,63}[ ]*$/;
// /^[^]+[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*[^]+/;

/**
 * 本システムで扱うことが可能な権限ID
 * ※サーバのアカウントマスタテーブル(account_master)と一致していること
 */
export const ROLE_CODE = {
  // Legal person
  ADMIN: 2,
  // 端末の設定変更可能な利用者
  USER: 3,
  // 履歴の参照が可能な利用者
  VIEWER: 4,
  // ログのみ参照可能な利用者
  VIEWER_REPORT_ONLY: 5,
} as const;

export const MENU_VALUE = {
  // Dashboar Menu
  DASHBOARD: 'Dashboard',
  // Report Menu
  REPORTS: 'Reports',
  // Device Menu
  DEVICE: 'Devices',
  // User Menu
  USERS: 'Users',
  // Remote Setting menu
  REMOTE_SETTINGS: 'Remote Settings',
  // Groups Menu
  GROUPS: 'Groups',
  // Add Group Menu
  ADD_GROUP: 'add-group',
  // Device List Menu
  DEVICE_LIST: 'Devicelist',
  // Language Count Menu
  LANGUAGE_COUNT: 'lanCount',
  // Language Rarget Menu
  TO_LANGUAGE: 'toLan',
  // Language Source Menu
  FROM_LANGUAGE: 'fromLan',
  // log Data
  LOG_DATA: 'log data',
  // Account Setting menu
  ACCOUNT_SETTINGS: 'Account Settings',
  // Device Manager
  DEVICE_MANAGER: 'Device Manager',
  SECURITY: 'Security',
} as const;

export const MENU_INDEX = {
  // Dashboar Menu
  DASHBOARD: 0,
  DEVICE_MANAGER: 1,
  REPORTS: 2,
  // Device List Menu
  // DEVICE_LIST: 2,
  // Account Setting menu
  USERS: 3,
  SECURITY:4
  // Device Manager
};

export const SUB_MENU_INDEX = {
  // Language Count Menu
  LANGUAGE_COUNT: 1,
  // Language Source Menu
  FROM_LANGUAGE: 2,
  // Language Rarget Menu
  TO_LANGUAGE: 3,
  // Log Data
  LOG_DATA: 4,
  // User Menu
  USERS: 5,
  // Remote Setting menu
  REMOTE_SETTINGS: 6,
  // Groups Menu
  GROUPS: 7,
  // Device Menu
  DEVICE: 8,
  // Add Group Menu
  ADD_GROUP: 8,
};

// Group Name Validation
// export const GROUP_NAME_VALIDATION = /^[^\s].+[^\s]$/;
// export const GROUP_NAME_VALIDATION = /^[-a-zA-Z0-9-()]+(\s+[-a-zA-Z0-9-()]+)*$/;
export const GROUP_NAME_VALIDATION = /^[^\s]+(\s+[^\s]+)*$/;
export const SSID_VALIDATION = /^(?=.*\S).+$/;
export const WIFI_PASSWORD_PATTERN = /^(?:.{8,63}|[0-9A-Fa-f]{64})$/
/* eslint-disable */
export const CA_USER_PASSWORD_PATTERN = /^[\x00-\x7F]{8,64}$/;
/* eslint-enable */
// export const PROXY_NAME_VALIDATION =
//   /^$|^[a-zA-Z0-9]+(\\-[a-zA-Z0-9]+)*(\\.[a-zA-Z0-9]+(\\-[a-zA-Z0-9]+)*)*$/;
// export const PROXY_NAME_VALIDATION = /^[^.\n]+(?:\.[^.\n]+)+$/;
// export const PROXY_NAME_VALIDATION =
// /^$|^[a-zA-Z0-9]+(\\-[a-zA-Z0-9]+)*(\\.[a-zA-Z0-9]+(\\-[a-zA-Z0-9]+)*)*$/;

// export const BYPASS_PROXY_VALIDATION =
//   /^[a-zA-Z0-9*]+(\\-[a-zA-Z0-9*]+)*(\\.[a-zA-Z0-9*]+(\\-[a-zA-Z0-9*]+)*)*/;
export const PROXY_NAME_VALIDATION =
  /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$|^(([a-zA-Z]|[a-zA-Z][a-zA-Z0-9-]*[a-zA-Z0-9])\.)*([A-Za-z]|[A-Za-z][A-Za-z0-9-]*[A-Za-z0-9])$/;

export const BYPASS_PROXY_VALIDATION =
  /^$|^[a-zA-Z0-9*]+(-[a-zA-Z0-9*]+)*([.][a-zA-Z0-9*]+(-[a-zA-Z0-9*]+)*)*([.][a-zA-Z0-9*]+(-[a-zA-Z0-9*]+)*([.][a-zA-Z0-9*]+(-[a-zA-Z0-9*]+)*)*)*$/;

export const IP_PORT_VALIDATION =
  /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/;
export const IP_ADDRESS_VALIDATION =
  /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
export const NETWORK_PREFIX = /^([0-9]|[12][0-9]|3[012])$/;
// export const GROUP_NAME_VALIDATION = /^[^\uFF00-\uFFFE\s]+(\s+[^\s,''":!]+)*$/;

// Password Strength Validation
// export const UPPER_N_LOWER_PASS_VALIDATION = /([a-z].*[A-Z])|([A-Z].*[a-z])/;
// export const NUMBER_PASS_VALIDATION = /([0-9])/;
// export const SPECIAL_CHAR_PASS_VALIDATION = /([!,%,&,@,#,$,^,*,?,_,~,(,),{},=,.,+,-])/;
export const USER_PASSWORD_PATTERN =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-\-, ,!,%,&,@,#,$,^,*,?,_,~,(,),{},=,.,+])[A-Za-z\d, ,!,%,&,@,#,$,^,*,?,_,~,(,),{},=,.,+,-]{8,16}$/;

  export const ZIP_CODE_PATTERN = /^\s*[0-9a-zA-Z\\-]{0,}\s*$/;

export const VALIDATION_MESSAGE = {
  GROUP_NAME_ALREADY_EXIST: 'Group name already exists.',
  NO_SPACE_AT_START_N_END:
    'Cannot have spaces at beginning or end of Group name.',
  GROUP_NAME_MAX_LENGTH: 'Max number of character for Group Name is 256.',
  PIN_CODE_VALIDATION: 'PIN code must be 6 digits.',
  REQUIRED: 'Required.',
};
// colors for user profiles
export const colorsArray = [
  '#80D8FF',
  '#D9D9D9',
  '#7FCEF0',
  '#FFD514',
  '#FFDF4D',
  '#F09A8F',
  '#80FFAD',
  '#EA7162',
  '#B9DBF8',
  '#FFB91A',
  '#FF8D4D',
  '#55C47C',
  '#8BC3F4',
  '#FFD880',
  '#2CDDEC',
  '#55C47C',
];
// Regular Expression for Japanese characters
export const regexJapanese =
  /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f\u3131-\uD79D]/;

// Regular Expression for notification details link generation
export const linkRegex = /{([^{}|]+)\|([^{}|]+)}/g;

export const GA_SECRET_KEY = 'G-WBCCSLWJX2';

export const PATH_NAMES = {
  DEVICEDETAILS: 'devicedetails',
  GROUPS: 'groups',
  NEWGROUP: 'newgroup',
  GROUPDETAIL: 'groupdetail',
  FROMLANGLIST: 'fromlanglist',
  TOLANGLIST: 'tolanglist',
};

export const SERVER_MESSAGE = {
  WARN_INPUT_PARAM: 'WARN_INPUT_PARAM', // wrong input parameter - 400
  ERR_REQUEST: 'ERR_REQUEST', // Bad request - 400
  ERR_UPDATE_DENIED: 'ERR_UPDATE_DENIED',
  ERR_UNKNOWN: 'ERR_UNKNOWN', // UNKNOWN - 500
  GROUP_ACCESS_DENIED: 'GROUP_ACCESS_DENIED',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR', // UNKNOWN - 500
  STACK_OVERFLOW_ERROR: 'STACK_OVERFLOW_ERROR',
  REQUEST_TIMEOUT_ERROR: 'REQUEST_TIMEOUT_ERROR',
  // Entity Not Found
  USER_NOT_FOUND: 'INFO_NO_USER', // user not found - 404
  TEMP_USER_NOT_FOUND: 'INFO_NO_TEMP_USER', // user not found - 404
  LANGUAGE_NOT_FOUND: 'INFO_NO_LANGUAGE', // Language not found - 404
  DEVICE_NOT_FOUND: 'INFO_NO_DEVICE', // device not found - 404
  GROUP_UPDATE_VALIDATION_FAILED: 'ERR_UPDATE_DENIED', // unauth - 401
  GROUP_NOT_FOUND: 'INFO_NO_GROUP', // no group found - 404
  DATA_NOT_FOUND: 'INFO_NO_DATA', // data not found -404
  AUTH_DATA_NOT_FOUND: 'INFO_NO_AUTH_DATA', // 404
  ONE_TIME_KEY_NOT_FOUND: 'INFO_NO_ONE_TIME_KEY', // token not found - 404
  CORP_NOT_FOUND: 'INFO_NO_CORPORATION', // corp not found -404

  // Group Service
  UNGROUPED_NOT_ALLOWED: 'WARN_UNGROUPED_NOT_ALLOWED',
  CORP_NO_ACCESS: 'CORPORATION_ACCESS_DENIED',
  GROUP_NO_ACCESS: 'GROUP_ACCESS_DENIED',
  DUPLICATE_GROUP_NAME: 'WARN_GROUP_NAME_EXIST',
  CORP_DETAIL_NOT_FOUND: 'CORP_DETAIL_NOT_FOUND',
  // Operation Success
  CREATE_OK: 'SUCCESSFULLY_CREATED', // 200
  UPDATE_OK: 'SUCCESSFULLY_UPDATED',
  DELETE_OK: 'SUCCESSFULLY_DELETED',
  FETCH_OK: 'SUCCESSFULLY_FETCHED',
  SUCCESSFULLY_SEND_MAIL: 'SUCCESSFULLY_SEND_MAIL',
  INFO_NO_GROUP: 'INFO_NO_GROUP',
  // User Service
  USER_ALREADY_REGISTERED: 'USER_ALREADY_REGISTERED', // 400
  KEY_CREATION_FAILED: 'ONE_TIME_KEY_CREATION_FAILED',
  AUTH_NOT_ALLOWED: 'AUTH_NOT_ALLOWED',
  DELETE_NOT_ALLOWED: 'DELETE_NOT_ALLOWED',
  ONE_TIME_KEY_EXPIRED: 'ONE_TIME_KEY_EXPIRED',
  ONE_TIME_KEY_CHECK_FAILED: 'ONE_TIME_KEY_CHECK_FAILED',
  REGISTRATION_KEY_MAIL_MISMATCH: 'ONE_TIME_MAIL_MISMATCH',
  REGISTRATION_SEND_MESSAGE_FAILED: 'REGISTRATION_SEND_MESSAGE_FAILED',
  OLD_AND_NEW_PASSWORD_SAME: 'OLD_AND_NEW_PASSWORD_SAME',
  USER_ROLE_CHANGED: 'USER_ROLE_CHANGED',
  INFO_NO_USER: 'INFO_NO_USER',
  USER_ALREADY_CONSENTED: 'USER_ALREADY_CONSENTED',
  ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN:
    'ROLE_UPDATE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN',
  ROLE_UPDATE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST:
    'ROLE_UPDATE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST',
  DELETE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN:
    'DELETE_NOT_ALLOWED_MUST_NEED_OTHER_ADMIN',
  DELETE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST:
    'DELETE_NOT_ALLOWED_CORPORATE_CONTACT_EMAIL_EXIST',
  USER_IS_NOT_ADMIN: 'USER_IS_NOT_ADMIN',
  // Account
  INFO_ALREADY_INVITED: 'INFO_ALREADY_INVITED',
  INFO_ALREADY_REGISTERED: 'INFO_ALREADY_REGISTERED',
  // Language Service

  PERIOD_CONTRADICTION: 'INFO_PERIOD_CONTRADICTION',
  CORP_ID_NOT_FOUND: 'INFO_CORP_ID_INVALID',
  OVER_COLUMN_SIZE: 'INFO_DATA_OVER',

  // Device service

  SETTING_INFO_NOT_AVAILABLE: 'SETTING_INFO_NOT_AVAILABLE', // 404
  DEVICE_ALREADY_DEREGISTERED: 'DEVICE_ALREADY_DEREGISTERED', // 400
  PIN_REQUIRED: 'PIN_REQUIRED', // 400
  LOST_MODE_ALREADY_ENABLED: 'LOST_MODE_ALREADY_ENABLED',
  ACCOUNT_NOT_FOUND: 'INFO_NO_ACCOUNT', // 404
  DEVICE_IN_LOST_MODE: 'DEVICE_IN_LOST_MODE', // 400
  LOST_MODE_ENABLE_REQUIRED: 'LOST_MODE_ENABLE_REQUIRED', // 400
  LOST_MODE_ALREADY_DISABLED: 'LOST_MODE_ALREADY_DISABLED', // 400
  DEVICE_DEREGISTERED: 'DEVICE_DEREGISTERED', // 400
  INFO_NO_DATA: 'INFO_NO_DATA',
  // Remote Device Service Message
  WARN_INVALID_AUTH: 'WARN_INVALID_AUTH', // 400
  DEVICE_RESTORE_INITIATED: 'DEVICE_RESTORE_INITIATED', // 400

  // Translation Service

  INFO_PERIOD_CONTRADICTION: 'INFO_PERIOD_CONTRADICTION',
  INFO_DATA_OVER: 'INFO_DATA_OVER',
  WARN_NO_SETTING: 'WARN_NO_SETTING',

  // Remote setting service
  WIFI_SETTING_EXIST: 'WiFi_SETTING_EXIST', // 400
  NO_WIFI_SETTING_EXIST: 'NO_WiFi_SETTING_EXIST', // 400

  // Auth Service
  INVALID_TOKEN: 'INVALID_TOKEN', // 400
  EXPIRED_TOKEN: 'EXPIRED_TOKEN', // 400
  WRONG_CREDENTIALS: 'WRONG_CREDENTIALS', // 400
  LOGIN_SUCCESS: 'LOGIN_SUCCESS', // 200
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS', // 200
  NOT_LOGGED_IN: 'NOT_LOGGED_IN', // 400
  NO_INTERNET: 'NO_INTERNET', // 400
  ACCESS_PERMISSION_DENIED: 'ACCESS_PERMISSION_DENIED',
  ACCOUNT_LOCKED: 'ACCOUNT_LOCKED',

  // EULA Service
  USER_CONSENT_REQUIRED: 'USER_CONSENT_REQUIRED', // 401
  SUBSCRIPTION_PLAN_CHANGED: 'SUBSCRIPTION_PLAN_CHANGED',

  // Refresh token
  REFRESH_SUCCESS: 'REFRESH_SUCCESS',

  // security setting
  TRANS_HISTORY_PREVENTION_ALREADY_UPDATED:'TRANS_HISTORY_PREVENTION_ALREADY_UPDATED',
} as const;

export type ALL_ERROR_TYPE =
  (typeof SERVER_MESSAGE)[keyof typeof SERVER_MESSAGE];

export const ERROR_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  FORBIDDEN_ERROR: 403,
  ENTITY_ERROR: 422,
  INVALID_REQUEST: 500,
  UNKNOWN: 501,
};

export const STATUS_COLOR_CODE = {
  YELLOW: '#F6AB00',
  RED: '#E1341E',
  GRAY: '#939598',
  GREEN: '#00A73B',
  NAVYBLUE: '#009de1',
  BLUE: '#007EB4',
  NONE: '',
};

export const FEATURE_PERMISSION = {
  GROUP_BASIC_ADD: 'GROUP_BASIC_ADD',
  GROUP_BASIC_DELETE: 'GROUP_BASIC_DELETE',
  GROUPMANAGEMENT: {
    GROUPNAME: {
      EDIT: 'EDIT',
    },
    GROUPDEVICE: {
      VIEWLIST: 'VIEW_LIST',
    },
    GROUPUSER: {
      VIEWLIST: 'VIEW_LIST',
    },
    GROUPUSERMODAL: {
      VIEWLIST: 'VIEW_LIST',
      ADD: 'ADD',
      DELETE: 'DELETE',
    },
    GROUPBASIC: {
      ADD: 'ADD',
      DELETE: 'DELETE',
      VIEWLIST: 'VIEW_LIST',
      VIEWDETAILS: 'VIEW_DETAILS',
    },
    REMOTECONTROLWIFI: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
    GROUPDEVICEMODAL: {
      VIEWLIST: 'VIEW_LIST',
      ADD: 'ADD',
      DELETE: 'DELETE',
    },
    REMOTECONTROLFEATURELIMIT: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
    REMOTECONTROLDATAPROTECTION: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
  },
  ACCOUNTMANAGEMENT: {
    USERBASIC: {
      VIEWLIST: 'VIEW_LIST',
      SEARCH: 'SEARCH',
      VIEWDETAILS: 'VIEW_DETAILS',
      DELETE: 'DELETE',
    },
    USERINVITE: { ADD: 'ADD', EDIT: 'EDIT' },
    USERROLE: { EDIT: 'EDIT' },
  },
  DEVICEMANAGEMENT: {
    DEVICEBASIC: {
      VIEWLIST: 'VIEW_LIST',
      SEARCH: 'SEARCH',
      VIEWDETAILS: 'VIEW_DETAILS',
    },
    DEVICELOSTMODE: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
    DEVICENAME: {
      EDIT: 'EDIT',
    },
    DEVICEINITIALIZATION: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
    DEVICETRANSLATIONHISTORY: {
      VIEWLIST: 'VIEW_LIST',
      EXPORT: 'EXPORT',
    },
  },
  PROFILEMANAGEMENT: {
    USERPASSWORD: {
      EDIT: 'EDIT',
    },
    USERPROFILE: {
      VIEWDETAILS: 'VIEW_DETAILS',
    },
    USERNAME: {
      EDIT: 'EDIT',
    },
  },
  CORPORATEMANAGEMENT: {
    CORPORATEPROFILE: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
    CORPORATE_PLAN: {
      EDIT: 'EDIT',
    },
  },
  REPORTING: {
    TRANSLATIONCOUNTTO: {
      VIEWLIST: 'VIEW_LIST',
      EXPORT: 'EXPORT',
    },
    TRANSLATIONCOUNT: {
      VIEWLIST: 'VIEW_LIST',
      EXPORT: 'EXPORT',
    },
    TRANSLATIONCOUNTFROM: {
      VIEWLIST: 'VIEW_LIST',
      EXPORT: 'EXPORT',
    },
    LOGDATA: {
      EXPORT: 'EXPORT',
    },
  },
  DASHBOARDMANAGEMENT: {
    DASHBOARDPERIODSWITCHING: {
      VIEWLIST: 'VIEW_LIST',
    },
  },
  NOTIFICATIONMANAGEMENT: {
    NOTIFICATIONBASIC: {
      VIEWLIST: 'VIEW_LIST',
    },
  },
  SECURITYMANAGEMENT: {
    TRANSLATIONHISTORYCONTROL: {
      VIEWDETAILS: 'VIEW_DETAILS',
      EDIT: 'EDIT',
    },
  },
};
