/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useState, useEffect } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useForm, Controller } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  Skeleton,
  TextField,
  InputAdornment,
  IconButton,
  ClickAwayListener,
} from '@mui/material';
import clsx from 'clsx';
import PersonalSettingsTemplate from '../Templates/PersonalSettingsTemplate';
import { useStyles } from '../Templates/CommonStyle';
import Loader from '../Atoms/Loader';
import useCorporateProfile from '../../hooks/useCorporateProfile';
import { ReactComponent as RightWhite } from '../../images/icon/Right_white.svg';
import { ReactComponent as ContactInfoIcon } from '../../images/icon/vuesax-linear-card.svg';
import { ReactComponent as CorportateIcon } from '../../images/icon/corportate.svg';
import { ReactComponent as CloseIcon } from '../../images/icon/close-gray.svg';
import { ReactComponent as ArrowDown } from '../../images/icon/down-arrow.svg';
import { ReactComponent as OrderHistory } from '../../images/icon/order_history.svg';
import { ReactComponent as PaymentInfo } from '../../images/icon/payment_info.svg';
import { ReactComponent as NetherlandsAntillesFlag } from '../../images/icon/netherlands_antilles_flag.svg';
import CountryListWithDialCode from '../Modecules/CountryListWithDialCode';
import CountrySelectCard from '../Modecules/CountrySelectCard';
import CountryStateList from '../Modecules/CountryStateList';
import CorporatePageBtnCard from '../Atoms/CorporatePageBtnCard';
import CorporatePageSubscriptionCard from '../Atoms/CorporatePageSubscriptionCard';
import {
  EMAIL_CHECK_PATTERN,
  FEATURE_PERMISSION,
  PAGE_PATH_NAME,
  ZIP_CODE_PATTERN,
} from '../../constants/constants';
import useAccountInfo from '../../hooks/useAccountInfo';
import { LOCALE_CODE } from '../../hooks/useLocale';

const CorporateProfilePage = (): JSX.Element => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { accountInfo } = useAccountInfo();

  const cardWidthRef = React.useRef<HTMLDivElement>(null);
  const [cardWidth, setcardWidth] = useState<number>(0);

  useEffect(() => {
    const compareSize = () => {
      if (cardWidthRef.current) {
        setcardWidth(cardWidthRef.current.clientWidth - 32);
      }
    };
    compareSize();
    window.addEventListener('load', compareSize);
    window.addEventListener('resize', compareSize);

    return () => window.removeEventListener('resize', compareSize);
  }, [cardWidthRef]);

  // custom hook
  const {
    isSaveBtnEnabled,
    setIsSaveBtnEnabled,
    onClickSaveBtn,
    handleSelectBoxClick,
    handleSelectPhoneDialOpen,
    handleSelectCountryInfo,
    selectAnchor,
    selectOpen,
    countryFlagCode,
    countryDialCode,
    handleSelectCountryBoxClick,
    handleSelectCountryDialOpen,
    handleSelectCountry,
    selectAnchorCountry,
    selectOpenCountry,
    countryNameCode,
    countryName,
    handleSelectStateBoxClick,
    handleSelectStateDialOpen,
    handleSelectState,
    selectAnchorState,
    selectOpenState,
    stateName,
    onClickSubscriptionBtn,
    onClickOrderHistoryBtn,
    onClickPaymentInfoBtn,
    contactInfo,
    isCallingApi,
    isCallingUpdateApi,
    t,
    subscriptionInfo,
    phoneNumberRef,
  } = useCorporateProfile();

  // start form
  const {
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
    control,
    trigger,
    // clearErrors,
  } = useForm({
    criteriaMode: 'all',
    shouldFocusError: false,
    mode: 'onChange' || 'onSubmit',
  });
  useEffect(() => {
    setValue('corporateName', contactInfo.corporateName);
    setValue('firstName', contactInfo.firstName);
    setValue('lastName', contactInfo.lastName);
    setValue('email', contactInfo.email);
    setValue('address', contactInfo.address);
    setValue('phoneNumber', contactInfo.tel);
    setValue('zipCode', contactInfo.zipCode);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactInfo]);

  const newCorporateName = watch('corporateName');
  const newFirstName = watch('firstName');
  const newLastName = watch('lastName');
  const newEmail = watch('email');
  const newAddress = watch('address');
  const newPhoneNo = watch('phoneNumber');

  const newZipCode = watch('zipCode');

  // const [isSaveBtnEnabled, setIsSaveBtnEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      newCorporateName?.trim().length > 0 &&
      newEmail &&
      !errors.corporateName &&
      !errors.firstName &&
      !errors.email &&
      !errors.address &&
      !errors.lastName &&
      !errors.zipCode &&
      !errors.phoneNumber &&
      (newCorporateName?.trim() !== contactInfo.corporateName ||
        newFirstName?.trim() !== contactInfo.firstName ||
        newLastName?.trim() !== contactInfo.lastName ||
        newEmail?.trim() !== contactInfo.email ||
        newAddress?.trim() !== contactInfo.address ||
        newPhoneNo?.trim() !== contactInfo.tel ||
        newZipCode?.trim() !== contactInfo.zipCode ||
        countryDialCode !== contactInfo.countryCode ||
        countryFlagCode !== contactInfo.countryFlag ||
        countryName !== contactInfo.country ||
        stateName !== contactInfo.state)
    ) {
      setIsSaveBtnEnabled(true);
    } else {
      setIsSaveBtnEnabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contactInfo,
    newAddress,
    newCorporateName,
    newEmail,
    newFirstName,
    newLastName,
    newPhoneNo,
    newZipCode,
    countryDialCode,
    countryFlagCode,
    countryName,
    stateName,
    errors.corporateName,
    errors.firstName,
    errors.email,
    errors.address,
    errors.lastName,
    errors.phoneNumber,
    errors.zipCode,
  ]);

  // start validate
  /* eslint-disable @typescript-eslint/no-unsafe-call */
  const validateCorporateName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      return t('corporateProfile.validation.nameCharLimit');
    }

    return true;
  };
  const [isValidateCorporateName, setIsValidateCorporateName] = useState(false);
  const validateEmail = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 256) {
      setIsValidateCorporateName(true);

      return t('common.validationError.email.maxLength');
    }
    setIsValidateCorporateName(false);

    return true;
  };
  const validateFirstName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 128) {
      return t('corporateProfile.validation.fnameCharLimit');
    }

    return true;
  };
  const validateAddress = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 500) {
      return t('corporateProfile.validation.addressCharLimit');
    }

    return true;
  };
  const validateLastName = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 128) {
      return t('corporateProfile.validation.lnameCharLimit');
    }

    return true;
  };
  const validateZipCode = (value: string) => {
    const trimmedValue = value?.trim();
    if (trimmedValue?.length > 20) {
      return t('corporateProfile.validation.zipCodeCharLimit');
    }

    return true;
  };
  /* eslint-enable @typescript-eslint/no-unsafe-call */
  // end validate
  // start click away listen
  // const handleClickAwayCorporateNameField = () => {
  //   clearErrors('corporateName');
  // };
  // const handleClickAwayFirstNameField = () => {
  //   clearErrors('firstName');
  // };
  // const handleClickAwayEmailField = () => {
  //   clearErrors('email');
  // };
  // const handleClickAwayAddressField = () => {
  //   clearErrors('address');
  // };
  // const handleClickAwayLastNameField = () => {
  //   clearErrors('lastName');
  // };
  // const handleClickAwayZipCodeField = () => {
  //   clearErrors('zipCode');
  // };
  // const handleClickAwayPhoneNumberField = () => {
  //   clearErrors('phoneNumber');
  // };
  // end click away listen

  const ViewAccess =
    accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
      FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE.VIEWDETAILS,
    );
  const NoEditAccess =
    !accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE?.includes(
      FEATURE_PERMISSION.CORPORATEMANAGEMENT.CORPORATEPROFILE.EDIT,
    );
  useEffect(() => {
    // eslint-disable-next-line
    if (!ViewAccess) {
      navigate(PAGE_PATH_NAME.DASHBOARD);
    }
    // eslint-disable-next-line
  }, [
    accountInfo.featurePermissions.CORPORATEMANAGEMENT?.CORPORATEPROFILE,
    pathname,
  ]);

  // start Close Icon Handle
  // start corporate name Field close Icon
  const [activateCorporateNameCloseIcon, setActivateCorporateNameCloseIcon] =
    useState(false);
  const [isFocusedCorporateName, setIsFocusedCorporateName] = useState(false);

  const handleActivateCorporateNameCloseIcon = () => {
    if (newCorporateName?.length !== 0) {
      setActivateCorporateNameCloseIcon(true);
    }
  };

  const handleDeactivateCorporateNameCloseIcon = () => {
    if (!isFocusedCorporateName) {
      setActivateCorporateNameCloseIcon(false);
    }
  };
  const handleCorporateNameFocus = () => {
    setIsFocusedCorporateName(true);
  };

  const handleCorporateNameBlur = () => {
    setIsFocusedCorporateName(false);
    setActivateCorporateNameCloseIcon(false);
  };
  // end corporate Field close Icon
  // start first name Field close Icon
  const [activateFirstNameCloseIcon, setActivateFirstNameCloseIcon] =
    useState(false);
  const [isFocusedFirstName, setIsFocusedFirstName] = useState(false);

  const handleActivateFirstNameCloseIcon = () => {
    if (newFirstName?.length > 0 && !NoEditAccess) {
      setActivateFirstNameCloseIcon(true);
    }
  };

  const handleDeactivateFirstNameCloseIcon = () => {
    if (!isFocusedFirstName) {
      setActivateFirstNameCloseIcon(false);
    }
  };
  const handleFirstNameFocus = () => {
    setIsFocusedFirstName(true);
  };

  const handleFirstNameBlur = () => {
    setIsFocusedFirstName(false);
    setActivateFirstNameCloseIcon(false);
  };
  // end first Field close Icon

  // start email Field close Icon
  const [activateEmailCloseIcon, setActivateEmailCloseIcon] = useState(false);
  const [isFocusedEmail, setIsFocusedEmail] = useState(false);

  const handleActivateEmailCloseIcon = () => {
    if (newEmail?.length > 0 && !NoEditAccess) {
      setActivateEmailCloseIcon(true);
    }
  };

  const handleDeactivateEmailCloseIcon = () => {
    if (!isFocusedEmail) {
      setActivateEmailCloseIcon(false);
    }
  };
  const handleEmailFocus = () => {
    setIsFocusedEmail(true);
  };

  const handleEmailBlur = () => {
    setIsFocusedEmail(false);
    setActivateEmailCloseIcon(false);
  };
  // end email Field close Icon

  // start address Field close Icon
  const [activateAddressCloseIcon, setActivateAddressCloseIcon] =
    useState(false);
  const [isFocusedAddress, setIsFocusedAddress] = useState(false);

  const handleActivateAddressCloseIcon = () => {
    if (newAddress?.length > 0 && !NoEditAccess) {
      setActivateAddressCloseIcon(true);
    }
  };

  const handleDeactivateAddressCloseIcon = () => {
    if (!isFocusedAddress) {
      setActivateAddressCloseIcon(false);
    }
  };
  const handleAddressFocus = () => {
    setIsFocusedAddress(true);
  };

  const handleAddressBlur = () => {
    setIsFocusedAddress(false);
    setActivateAddressCloseIcon(false);
  };
  // end address Field close Icon

  // start Last name Field close Icon
  const [activateLastNameCloseIcon, setActivateLastNameCloseIcon] =
    useState(false);
  const [isFocusedLastName, setIsFocusedLastName] = useState(false);

  const handleActivateLastNameCloseIcon = () => {
    if (newLastName?.length > 0 && !NoEditAccess) {
      setActivateLastNameCloseIcon(true);
    }
  };

  const handleDeactivateLastNameCloseIcon = () => {
    if (!isFocusedLastName) {
      setActivateLastNameCloseIcon(false);
    }
  };
  const handleLastNameFocus = () => {
    setIsFocusedLastName(true);
  };

  const handleLastNameBlur = () => {
    setIsFocusedLastName(false);
    setActivateLastNameCloseIcon(false);
  };
  // end last name Field close Icon

  // start phone no Field close Icon
  const [activatePhoneNoCloseIcon, setActivatePhoneNoCloseIcon] =
    useState(false);
  const [isFocusedPhoneNo, setIsFocusedPhoneNo] = useState(false);

  const handleActivatePhoneNoCloseIcon = () => {
    if (newPhoneNo?.length > 0 && !NoEditAccess) {
      setActivatePhoneNoCloseIcon(true);
    }
  };

  const handleDeactivatePhoneNoCloseIcon = () => {
    if (!isFocusedPhoneNo) {
      setActivatePhoneNoCloseIcon(false);
    }
  };
  const handlePhoneNoFocus = () => {
    setIsFocusedPhoneNo(true);
  };

  const handlePhoneNoBlur = () => {
    setIsFocusedPhoneNo(false);
    setActivatePhoneNoCloseIcon(false);
  };
  // end phone no Field close Icon

  // start zip Field close Icon
  const [activateZipCodeCloseIcon, setActivateZipCodeCloseIcon] =
    useState(false);
  const [isFocusedZipCode, setIsFocusedZipCode] = useState(false);

  const handleActivateZipCodeCloseIcon = () => {
    if (newZipCode?.length > 0 && !NoEditAccess) {
      setActivateZipCodeCloseIcon(true);
    }
  };

  const handleDeactivateZipCodeCloseIcon = () => {
    if (!isFocusedZipCode) {
      setActivateZipCodeCloseIcon(false);
    }
  };
  const handleZipCodeFocus = () => {
    setIsFocusedZipCode(true);
  };

  const handleZipCodeBlur = () => {
    setIsFocusedZipCode(false);
    setActivateZipCodeCloseIcon(false);
  };
  // end zip code Field close Icon

  // end Close Icon Handle
  const handleMouseDown = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleChangePhoneNumber = (e: any) => {
    const regex = /^[0-9\b]+$/;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    if (e.target.value === '' || regex.test(e.target.value)) {
      setValue('phoneNumber', e.target.value);
      void trigger('phoneNumber');
      if (e.target.value.length !== 0) {
        setActivatePhoneNoCloseIcon(true);
      } else {
        setActivatePhoneNoCloseIcon(false);
      }
    }
  };

  return (
    <PersonalSettingsTemplate titleText={t('corporateProfile.viewName')}>
      <Box sx={{ width: '100%' }}>
        <form onSubmit={handleSubmit(onClickSaveBtn)}>
          <Box className={classes.personalSettingBtnCard}>
            <Button
              id="userDetailSave"
              type="submit"
              variant="contained"
              className={classes.personalSettingBtn}
              disableRipple
              disabled={isCallingApi || !isSaveBtnEnabled || isCallingUpdateApi}
            >
              {isCallingUpdateApi ? (
                <Loader colorCode="#fff" />
              ) : (
                <RightWhite />
              )}

              <Typography className={classes.personalSettingBtnText}>
                {t('corporateProfile.button.save')}
              </Typography>
            </Button>
          </Box>
          <Box
            className={`${classes.personalSettingPageDivider} corporate-profile-row`}
          >
            <Box className="corporate-profile-col">
              <Box
                className={classes.corporateProfilePageCard}
                sx={{
                  height: '181px',
                  // maxWidth: '780px',
                }}
              >
                <Box className={classes.corporateProfilePageCardHeader}>
                  <CorportateIcon />
                  <Typography
                    className={classes.corporateProfilePageCardHeaderTitle}
                  >
                    {t('corporateProfile.label.corporateInfo')}
                  </Typography>
                </Box>
                <Box sx={{ display: 'flex' }}>
                  <Typography className={classes.inputLevelCorporateName}>
                    {t('corporateProfile.label.corporateName')}
                  </Typography>
                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>
                {isCallingApi && (
                  <Skeleton
                    variant="text"
                    animation="wave"
                    sx={{
                      p: '0px',
                      height: '38px',
                      marginTop: '6px',
                      marginBottom: '8px',
                    }}
                  />
                )}
                {!isCallingApi && (
                  // <ClickAwayListener
                  //   onClickAway={handleClickAwayCorporateNameField}
                  // >
                  <Box
                    sx={{
                      position: 'relative',
                    }}
                  >
                    <Controller
                      control={control}
                      name="corporateName"
                      rules={{
                        required: t('common.validationError.required'),
                        validate: validateCorporateName,
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          type="text"
                          value={field.value || ''}
                          fullWidth
                          margin="normal"
                          placeholder={t(
                            'corporateProfile.placeholder.corporate',
                          )}
                          autoComplete="off"
                          error={Boolean(errors.corporateName)}
                          onFocus={handleCorporateNameFocus}
                          onBlur={handleCorporateNameBlur}
                          onMouseOver={handleActivateCorporateNameCloseIcon}
                          onMouseLeave={handleDeactivateCorporateNameCloseIcon}
                          inputProps={{
                            maxLength: 257,
                          }}
                          // eslint-disable-next-line react/jsx-no-duplicate-props
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                sx={{
                                  marginLeft: '0px',
                                  width: '20px',
                                }}
                              >
                                <IconButton
                                  aria-label="close"
                                  onClick={() => {
                                    setValue('corporateName', '');
                                    void trigger('corporateName');
                                    setActivateCorporateNameCloseIcon(false);
                                  }}
                                  onMouseDown={handleMouseDown}
                                  edge="end"
                                  sx={{
                                    p: '8px',
                                    '&:hover': {
                                      backgroundColor: 'rgba(0, 0, 0, 0.04)',
                                    },
                                    display: activateCorporateNameCloseIcon
                                      ? ''
                                      : 'none',
                                  }}
                                  disableRipple
                                >
                                  <CloseIcon
                                    style={{
                                      width: '9.01px',
                                      height: '9.01px',
                                    }}
                                  />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              borderColor: errors.corporateName
                                ? '#E1341E !important'
                                : '#939598 !important',
                            },
                            '& .MuiOutlinedInput-root': {
                              borderRadius: '6px',
                              '&.Mui-focused fieldset': {
                                border: errors.corporateName
                                  ? '1px solid #E1341E !important'
                                  : '1px solid #939598 !important',
                              },
                            },
                          }}
                          className={classes.loginFormInput}
                          onChange={(event) => {
                            // handleOnChangeCorporateName(event.target.value);
                            field.onChange(event);
                            if (event.target.value.length !== 0) {
                              setActivateCorporateNameCloseIcon(true);
                            } else {
                              setActivateCorporateNameCloseIcon(false);
                            }
                          }}
                          disabled={NoEditAccess}
                        />
                      )}
                    />

                    {errors.corporateName && (
                      <Box className={classes.userNameError}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.corporateName && errors.corporateName.message}
                        </p>
                      </Box>
                    )}
                  </Box>
                  // </ClickAwayListener>
                )}
              </Box>
              <Box
                className={classes.corporateProfilePageCard}
                sx={{
                  // height: '417px',
                  // maxWidth: '780px',
                  marginTop: '20px',
                }}
              >
                <Box
                  sx={{ marginBottom: '20px !important' }}
                  className={classes.corporateProfilePageCardHeader}
                >
                  <ContactInfoIcon />
                  <Typography
                    className={classes.corporateProfilePageCardHeaderTitle}
                  >
                    {t('corporateProfile.label.contactInformation')}
                  </Typography>
                </Box>
                <Box className="corporate-input-row">
                  {accountInfo.locale !== LOCALE_CODE.JA && (
                    <Box className="corporate-input-col">
                      <Typography className={classes.inputLevel}>
                        {t('corporateProfile.label.firstName')}
                      </Typography>
                      {isCallingApi && (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            height: '38px',
                          }}
                        />
                      )}
                      {!isCallingApi && (
                        // <ClickAwayListener
                        //   onClickAway={handleClickAwayFirstNameField}
                        // >
                        <Box
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <Controller
                            control={control}
                            name="firstName"
                            rules={{
                              validate: validateFirstName,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={NoEditAccess}
                                type="text"
                                value={field.value || ''}
                                fullWidth
                                margin="normal"
                                placeholder={t(
                                  'corporateProfile.placeholder.firstName',
                                )}
                                autoComplete="off"
                                error={Boolean(errors.firstName)}
                                onFocus={handleFirstNameFocus}
                                onBlur={handleFirstNameBlur}
                                onMouseOver={handleActivateFirstNameCloseIcon}
                                onMouseLeave={
                                  handleDeactivateFirstNameCloseIcon
                                }
                                inputProps={{
                                  maxLength: 129,
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        marginLeft: '0px',
                                        width: '20px',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="close"
                                        onClick={() => {
                                          setValue('firstName', '');
                                          void trigger('firstName');
                                          setActivateFirstNameCloseIcon(false);
                                        }}
                                        onMouseDown={handleMouseDown}
                                        edge="end"
                                        sx={{
                                          p: '8px',
                                          '&:hover': {
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.04)',
                                          },
                                          display: activateFirstNameCloseIcon
                                            ? ''
                                            : 'none',
                                        }}
                                        disableRipple
                                      >
                                        <CloseIcon
                                          style={{
                                            width: '9.01px',
                                            height: '9.01px',
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: errors.firstName
                                      ? '#E1341E !important'
                                      : '#939598 !important',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '6px',
                                    '&.Mui-focused fieldset': {
                                      border: errors.firstName
                                        ? '1px solid #E1341E !important'
                                        : '1px solid #939598 !important',
                                    },
                                  },
                                }}
                                className={classes.loginFormInput}
                                onChange={(event) => {
                                  // handleOnChangeCorporateName(event.target.value);
                                  field.onChange(event);
                                  if (event.target.value.length !== 0) {
                                    setActivateFirstNameCloseIcon(true);
                                  } else {
                                    setActivateFirstNameCloseIcon(false);
                                  }
                                }}
                              />
                            )}
                          />

                          {errors.firstName && (
                            <Box className={classes.userNameError}>
                              <p style={{ padding: '0', margin: '0' }}>
                                {errors.firstName && errors.firstName.message}
                              </p>
                            </Box>
                          )}
                        </Box>
                        // </ClickAwayListener>
                      )}
                    </Box>
                  )}
                  <Box className="corporate-input-col">
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.lastName')}
                    </Typography>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      // <ClickAwayListener
                      //   onClickAway={handleClickAwayLastNameField}
                      // >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Controller
                          control={control}
                          name="lastName"
                          rules={{
                            validate: validateLastName,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              disabled={NoEditAccess}
                              value={field.value || ''}
                              fullWidth
                              margin="normal"
                              placeholder={t(
                                'corporateProfile.placeholder.lastName',
                              )}
                              autoComplete="off"
                              error={Boolean(errors.lastName)}
                              onFocus={handleLastNameFocus}
                              onBlur={handleLastNameBlur}
                              onMouseOver={handleActivateLastNameCloseIcon}
                              onMouseLeave={handleDeactivateLastNameCloseIcon}
                              inputProps={{
                                maxLength: 129,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      marginLeft: '0px',
                                      width: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        setValue('lastName', '');
                                        void trigger('lastName');
                                        setActivateLastNameCloseIcon(false);
                                      }}
                                      onMouseDown={handleMouseDown}
                                      edge="end"
                                      sx={{
                                        p: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                        display: activateLastNameCloseIcon
                                          ? ''
                                          : 'none',
                                      }}
                                      disableRipple
                                    >
                                      <CloseIcon
                                        style={{
                                          width: '9.01px',
                                          height: '9.01px',
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: errors.lastName
                                    ? '#E1341E !important'
                                    : '#939598 !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '6px',
                                  '&.Mui-focused fieldset': {
                                    border: errors.lastName
                                      ? '1px solid #E1341E !important'
                                      : '1px solid #939598 !important',
                                  },
                                },
                              }}
                              className={classes.loginFormInput}
                              onChange={(event) => {
                                // handleOnChangeCorporateName(event.target.value);
                                field.onChange(event);
                                if (event.target.value.length !== 0) {
                                  setActivateLastNameCloseIcon(true);
                                } else {
                                  setActivateLastNameCloseIcon(false);
                                }
                              }}
                            />
                          )}
                        />

                        {errors.lastName && (
                          <Box className={classes.userNameError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {errors.lastName && errors.lastName.message}
                            </p>
                          </Box>
                        )}
                      </Box>
                      // </ClickAwayListener>
                    )}
                  </Box>
                  {accountInfo.locale === LOCALE_CODE.JA && (
                    <Box className="corporate-input-col">
                      <Typography className={classes.inputLevel}>
                        {t('corporateProfile.label.firstName')}
                      </Typography>
                      {isCallingApi && (
                        <Skeleton
                          variant="text"
                          animation="wave"
                          sx={{
                            p: '0px',
                            height: '38px',
                          }}
                        />
                      )}
                      {!isCallingApi && (
                        // <ClickAwayListener
                        //   onClickAway={handleClickAwayFirstNameField}
                        // >
                        <Box
                          sx={{
                            position: 'relative',
                          }}
                        >
                          <Controller
                            control={control}
                            name="firstName"
                            rules={{
                              validate: validateFirstName,
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                disabled={NoEditAccess}
                                type="text"
                                value={field.value || ''}
                                fullWidth
                                margin="normal"
                                placeholder={t(
                                  'corporateProfile.placeholder.firstName',
                                )}
                                autoComplete="off"
                                error={Boolean(errors.firstName)}
                                onFocus={handleFirstNameFocus}
                                onBlur={handleFirstNameBlur}
                                onMouseOver={handleActivateFirstNameCloseIcon}
                                onMouseLeave={
                                  handleDeactivateFirstNameCloseIcon
                                }
                                inputProps={{
                                  maxLength: 129,
                                }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        marginLeft: '0px',
                                        width: '20px',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="close"
                                        onClick={() => {
                                          setValue('firstName', '');
                                          void trigger('firstName');
                                          setActivateFirstNameCloseIcon(false);
                                        }}
                                        onMouseDown={handleMouseDown}
                                        edge="end"
                                        sx={{
                                          p: '8px',
                                          '&:hover': {
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.04)',
                                          },
                                          display: activateFirstNameCloseIcon
                                            ? ''
                                            : 'none',
                                        }}
                                        disableRipple
                                      >
                                        <CloseIcon
                                          style={{
                                            width: '9.01px',
                                            height: '9.01px',
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: errors.firstName
                                      ? '#E1341E !important'
                                      : '#939598 !important',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '6px',
                                    '&.Mui-focused fieldset': {
                                      border: errors.firstName
                                        ? '1px solid #E1341E !important'
                                        : '1px solid #939598 !important',
                                    },
                                  },
                                }}
                                className={classes.loginFormInput}
                                onChange={(event) => {
                                  // handleOnChangeCorporateName(event.target.value);
                                  field.onChange(event);
                                  if (event.target.value.length !== 0) {
                                    setActivateFirstNameCloseIcon(true);
                                  } else {
                                    setActivateFirstNameCloseIcon(false);
                                  }
                                }}
                              />
                            )}
                          />

                          {errors.firstName && (
                            <Box className={classes.userNameError}>
                              <p style={{ padding: '0', margin: '0' }}>
                                {errors.firstName && errors.firstName.message}
                              </p>
                            </Box>
                          )}
                        </Box>
                        // </ClickAwayListener>
                      )}
                    </Box>
                  )}
                  <Box className="corporate-input-col">
                    <Box sx={{ display: 'flex' }}>
                      <Typography className={classes.inputLevel}>
                        {t('corporateProfile.label.email')}
                      </Typography>
                      <Typography
                        sx={{ marginTop: '4px' }}
                        className={classes.loginCardLevelMarker}
                      >
                        *
                      </Typography>
                    </Box>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      // <ClickAwayListener
                      //   onClickAway={handleClickAwayEmailField}
                      // >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Controller
                          control={control}
                          name="email"
                          rules={{
                            required: t('common.validationError.required'),
                            pattern: {
                              value: EMAIL_CHECK_PATTERN,
                              message: t(
                                'common.validationError.email.invalid',
                              ),
                            },
                            validate: validateEmail,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              disabled={NoEditAccess}
                              value={field.value || ''}
                              fullWidth
                              margin="normal"
                              placeholder={t(
                                'corporateProfile.placeholder.email',
                              )}
                              autoComplete="off"
                              error={Boolean(errors.email)}
                              onFocus={handleEmailFocus}
                              onBlur={handleEmailBlur}
                              onMouseOver={handleActivateEmailCloseIcon}
                              onMouseLeave={handleDeactivateEmailCloseIcon}
                              inputProps={{
                                maxLength: 257,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      marginLeft: '0px',
                                      width: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        setValue('email', '');
                                        void trigger('email');
                                        setActivateEmailCloseIcon(false);
                                      }}
                                      onMouseDown={handleMouseDown}
                                      edge="end"
                                      sx={{
                                        p: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                        display: activateEmailCloseIcon
                                          ? ''
                                          : 'none',
                                      }}
                                      disableRipple
                                    >
                                      <CloseIcon
                                        style={{
                                          width: '9.01px',
                                          height: '9.01px',
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: errors.email
                                    ? '#E1341E !important'
                                    : '#939598 !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '6px',
                                  '&.Mui-focused fieldset': {
                                    border: errors.email
                                      ? '1px solid #E1341E !important'
                                      : '1px solid #939598 !important',
                                  },
                                },
                              }}
                              className={classes.loginFormInput}
                              onChange={(event) => {
                                // handleOnChangeEmail(event.target.value);
                                field.onChange(event);
                                if (event.target.value.length !== 0) {
                                  setActivateEmailCloseIcon(true);
                                } else {
                                  setActivateEmailCloseIcon(false);
                                }
                              }}
                            />
                          )}
                        />

                        {errors.email && (
                          <Box className={classes.userNameError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {isValidateCorporateName &&
                                t('common.validationError.email.maxLength')}
                              {!isValidateCorporateName &&
                                errors.email &&
                                errors.email.message}
                            </p>
                          </Box>
                        )}
                      </Box>
                      // </ClickAwayListener>
                    )}
                  </Box>
                  <Box className="corporate-input-col" ref={cardWidthRef}>
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.phone')}
                    </Typography>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      <ClickAwayListener
                        // onClickAway={handleClickAwayPhoneNumberField}
                        onClickAway={handlePhoneNoBlur}
                      >
                        <Box sx={{ position: 'relative' }}>
                          <Controller
                            control={control}
                            name="phoneNumber"
                            rules={{
                              maxLength: { value: 16, message: 'Max Length' },
                            }}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                type="integer"
                                disabled={NoEditAccess}
                                onFocus={handlePhoneNoFocus}
                                // onBlur={handlePhoneNoBlur}
                                onMouseOver={handleActivatePhoneNoCloseIcon}
                                onMouseLeave={handleDeactivatePhoneNoCloseIcon}
                                inputProps={{ maxLength: 17 }}
                                // eslint-disable-next-line react/jsx-no-duplicate-props
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment
                                      position="start"
                                      sx={{ marginRight: '0px' }}
                                    >
                                      <IconButton
                                        aria-label="phoneNoClear"
                                        onClick={
                                          NoEditAccess
                                            ? () => console.log('')
                                            : handleSelectBoxClick
                                        }
                                        onMouseDown={handleMouseDown}
                                        edge="end"
                                        disableRipple
                                        sx={{
                                          padding: '0px',
                                          marginRight: '0px',
                                        }}
                                      >
                                        {countryFlagCode === 'AN' ? (
                                          <NetherlandsAntillesFlag
                                            style={{
                                              width: '30px',
                                              height: '20px',
                                            }}
                                          />
                                        ) : (
                                          <ReactCountryFlag
                                            style={{
                                              width: '27px',
                                              height: '20px',
                                              border:
                                                '0.5px solid rgba(217, 217, 217, .5)',
                                              // border:
                                              //   countryCode === 'JP'
                                              //     ? '0.5px solid rgba(217, 217, 217, .5)'
                                              //     : 'none',
                                            }}
                                            countryCode={countryFlagCode}
                                            svg
                                          />
                                        )}
                                        <ArrowDown
                                          className={classes.flagSelectArrow}
                                        />
                                      </IconButton>
                                      <Typography
                                        sx={{
                                          fontSize: '14px',
                                          color: newPhoneNo
                                            ? '#313133'
                                            : '#939598',
                                        }}
                                      >
                                        ({countryDialCode})
                                      </Typography>
                                    </InputAdornment>
                                  ),
                                  endAdornment: (
                                    <InputAdornment
                                      position="end"
                                      sx={{
                                        marginLeft: '0px',
                                        marginRight: '8px',
                                        width: '20px',
                                      }}
                                    >
                                      <IconButton
                                        aria-label="phoneNoClear"
                                        onClick={() => {
                                          setValue('phoneNumber', '');
                                          void trigger('phoneNumber');
                                          setActivatePhoneNoCloseIcon(false);
                                        }}
                                        onMouseDown={handleMouseDown}
                                        edge="end"
                                        sx={{
                                          p: '8px',
                                          '&:hover': {
                                            backgroundColor:
                                              'rgba(0, 0, 0, 0.04)',
                                          },
                                          display: activatePhoneNoCloseIcon
                                            ? ''
                                            : 'none',
                                        }}
                                        disableRipple
                                      >
                                        <CloseIcon
                                          style={{
                                            width: '9.01px',
                                            height: '9.01px',
                                          }}
                                        />
                                      </IconButton>
                                    </InputAdornment>
                                  ),
                                }}
                                value={field.value || ''}
                                fullWidth
                                margin="normal"
                                // placeholder="Phone number"
                                autoComplete="off"
                                onChange={handleChangePhoneNumber}
                                inputRef={phoneNumberRef}
                                className={clsx(
                                  classes.countryPhoneInput,
                                  classes.loginFormInput,
                                )}
                                sx={{
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    borderColor: errors.phoneNumber
                                      ? '#E1341E !important'
                                      : '#939598 !important',
                                  },
                                  '& .MuiOutlinedInput-root': {
                                    borderRadius: '6px',
                                    '&.Mui-focused fieldset': {
                                      border: errors.phoneNumber
                                        ? '1px solid #E1341E !important'
                                        : '1px solid #939598 !important',
                                    },
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    borderColor: errors.phoneNumber
                                      ? '#E1341E !important'
                                      : '#939598 !important',
                                  },
                                }}
                              />
                            )}
                          />
                          {errors?.phoneNumber && (
                            <Box className={classes.userNameError}>
                              <p style={{ padding: '0', margin: '0' }}>
                                {t(
                                  'corporateProfile.validation.PhoneNoCharLimit',
                                )}
                              </p>
                            </Box>
                          )}
                          <CountryListWithDialCode
                            width={cardWidth}
                            selectAnchor={selectAnchor}
                            selectOpen={selectOpen}
                            handleSelectPhoneDialOpen={
                              NoEditAccess
                                ? () => console.log('')
                                : handleSelectPhoneDialOpen
                            }
                            handleSelectCountryInfo={handleSelectCountryInfo}
                            selectedCountryCode={countryFlagCode}
                          />
                        </Box>
                      </ClickAwayListener>
                    )}
                  </Box>
                  <Box className="corporate-input-col">
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.address')}
                    </Typography>

                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      // <ClickAwayListener
                      //   onClickAway={handleClickAwayAddressField}
                      // >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Controller
                          control={control}
                          name="address"
                          rules={{
                            validate: validateAddress,
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              disabled={NoEditAccess}
                              value={field.value || ''}
                              fullWidth
                              margin="normal"
                              placeholder={t(
                                'corporateProfile.placeholder.address',
                              )}
                              autoComplete="off"
                              error={Boolean(errors.address)}
                              onFocus={handleAddressFocus}
                              onBlur={handleAddressBlur}
                              onMouseOver={handleActivateAddressCloseIcon}
                              onMouseLeave={handleDeactivateAddressCloseIcon}
                              inputProps={{
                                maxLength: 501,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      marginLeft: '0px',
                                      width: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        setValue('address', '');
                                        void trigger('address');
                                        setActivateAddressCloseIcon(false);
                                      }}
                                      onMouseDown={handleMouseDown}
                                      edge="end"
                                      sx={{
                                        p: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                        display: activateAddressCloseIcon
                                          ? ''
                                          : 'none',
                                      }}
                                      disableRipple
                                    >
                                      <CloseIcon
                                        style={{
                                          width: '9.01px',
                                          height: '9.01px',
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: errors.address
                                    ? '#E1341E !important'
                                    : '#939598 !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '6px',
                                  '&.Mui-focused fieldset': {
                                    border: errors.address
                                      ? '1px solid #E1341E !important'
                                      : '1px solid #939598 !important',
                                  },
                                },
                              }}
                              className={classes.loginFormInput}
                              onChange={(event) => {
                                // handleOnChangeAddress(event.target.value);
                                field.onChange(event);
                                if (event.target.value.length !== 0) {
                                  setActivateAddressCloseIcon(true);
                                } else {
                                  setActivateAddressCloseIcon(false);
                                }
                              }}
                            />
                          )}
                        />

                        {errors.address && (
                          <Box className={classes.userNameError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {errors.address && errors.address.message}
                            </p>
                          </Box>
                        )}
                      </Box>
                      // </ClickAwayListener>
                    )}
                  </Box>
                  <Box className="corporate-input-col">
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.country')}
                    </Typography>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      <CountrySelectCard
                        width={cardWidth}
                        selectAnchorCountry={selectAnchorCountry}
                        selectOpenCountry={selectOpenCountry}
                        handleSelectCountryDialOpen={
                          handleSelectCountryDialOpen
                        }
                        handleSelectCountry={handleSelectCountry}
                        handleSelectCountryBoxClick={
                          NoEditAccess
                            ? () => console.log('')
                            : handleSelectCountryBoxClick
                        }
                        selectedCountry={countryName}
                      />
                    )}
                  </Box>
                  <Box className="corporate-input-col">
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.state')}
                    </Typography>
                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      <CountryStateList
                        countryNameCode={countryNameCode}
                        width={cardWidth}
                        selectAnchorState={selectAnchorState}
                        selectOpenState={selectOpenState}
                        handleSelectStateDialOpen={handleSelectStateDialOpen}
                        handleSelectState={handleSelectState}
                        handleSelectStateBoxClick={
                          NoEditAccess
                            ? () => console.log('')
                            : handleSelectStateBoxClick
                        }
                        selectedState={stateName}
                      />
                    )}
                  </Box>
                  <Box className="corporate-input-col">
                    <Typography className={classes.inputLevel}>
                      {t('corporateProfile.label.zip')}
                    </Typography>

                    {isCallingApi && (
                      <Skeleton
                        variant="text"
                        animation="wave"
                        sx={{
                          p: '0px',
                          height: '38px',
                        }}
                      />
                    )}
                    {!isCallingApi && (
                      // <ClickAwayListener
                      //   onClickAway={handleClickAwayZipCodeField}
                      // >
                      <Box
                        sx={{
                          position: 'relative',
                        }}
                      >
                        <Controller
                          control={control}
                          name="zipCode"
                          rules={{
                            validate: validateZipCode,
                            pattern: {
                              value: ZIP_CODE_PATTERN,
                              message: t(
                                'corporateProfile.validation.zipCodePattern',
                              ),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              type="text"
                              disabled={NoEditAccess}
                              value={field.value || ''}
                              fullWidth
                              margin="normal"
                              placeholder={t(
                                'corporateProfile.placeholder.zip',
                              )}
                              autoComplete="off"
                              error={Boolean(errors.zipCode)}
                              onFocus={handleZipCodeFocus}
                              onBlur={handleZipCodeBlur}
                              onMouseOver={handleActivateZipCodeCloseIcon}
                              onMouseLeave={handleDeactivateZipCodeCloseIcon}
                              inputProps={{
                                maxLength: 21,
                              }}
                              // eslint-disable-next-line react/jsx-no-duplicate-props
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment
                                    position="end"
                                    sx={{
                                      marginLeft: '0px',
                                      width: '20px',
                                    }}
                                  >
                                    <IconButton
                                      aria-label="close"
                                      onClick={() => {
                                        setValue('zipCode', '');
                                        void trigger('zipCode');
                                        setActivateZipCodeCloseIcon(false);
                                      }}
                                      onMouseDown={handleMouseDown}
                                      edge="end"
                                      sx={{
                                        p: '8px',
                                        '&:hover': {
                                          backgroundColor:
                                            'rgba(0, 0, 0, 0.04)',
                                        },
                                        display: activateZipCodeCloseIcon
                                          ? ''
                                          : 'none',
                                      }}
                                      disableRipple
                                    >
                                      <CloseIcon
                                        style={{
                                          width: '9.01px',
                                          height: '9.01px',
                                        }}
                                      />
                                    </IconButton>
                                  </InputAdornment>
                                ),
                              }}
                              sx={{
                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                  borderColor: errors.zipCode
                                    ? '#E1341E !important'
                                    : '#939598 !important',
                                },
                                '& .MuiOutlinedInput-root': {
                                  borderRadius: '6px',
                                  '&.Mui-focused fieldset': {
                                    border: errors.zipCode
                                      ? '1px solid #E1341E !important'
                                      : '1px solid #939598 !important',
                                  },
                                },
                              }}
                              className={classes.loginFormInput}
                              onChange={(event) => {
                                // handleOnChangeZipCode(event.target.value);
                                field.onChange(event);
                                if (event.target.value.length !== 0) {
                                  setActivateZipCodeCloseIcon(true);
                                } else {
                                  setActivateZipCodeCloseIcon(false);
                                }
                              }}
                            />
                          )}
                        />

                        {errors.zipCode && (
                          <Box className={classes.userNameError}>
                            <p style={{ padding: '0', margin: '0' }}>
                              {errors.zipCode && errors.zipCode.message}
                            </p>
                          </Box>
                        )}
                      </Box>
                      // </ClickAwayListener>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className="corporate-profile-col">
              <CorporatePageSubscriptionCard
                currentPlan={
                  subscriptionInfo.currentPlan
                    ? subscriptionInfo.currentPlan
                    : '-'
                }
                /* eslint-disable no-nested-ternary */
                trialEnd={
                  subscriptionInfo.trialFlag
                    ? subscriptionInfo.expiredDate
                    : subscriptionInfo.nextBillingDate
                    ? subscriptionInfo.nextBillingDate
                    : '-'
                }
                trialFlag={subscriptionInfo.trialFlag}
                onClickBtn={onClickSubscriptionBtn}
                isCallingApi={isCallingApi}
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
              <CorporatePageBtnCard
                title={t('corporateProfile.label.orderHistory')}
                icon={<OrderHistory />}
                btnText={t('corporateProfile.button.viewDetails')}
                onClickBtn={onClickOrderHistoryBtn}
                isCallingApi={isCallingApi}
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
              <CorporatePageBtnCard
                title={t('corporateProfile.label.paymentInfo')}
                icon={<PaymentInfo />}
                btnText={t('corporateProfile.button.viewUpdate')}
                onClickBtn={onClickPaymentInfoBtn}
                isCallingApi={isCallingApi}
                isDisable
                shopifyFeatureEnabled={accountInfo.shopifyFeatureEnabled}
              />
            </Box>
          </Box>
        </form>
      </Box>
    </PersonalSettingsTemplate>
  );
};
export default CorporateProfilePage;