import { Box, Button, Typography } from '@mui/material';
import { useStyles } from './DialogStyle';

interface IProps {
  firstTitle: string;
  secondTitle: string;
  isFirstItemActive: boolean;
  onClickItemBtn: (value: boolean) => void;
}

const DialogSwapBox: React.FC<IProps> = ({
  firstTitle,
  secondTitle,
  isFirstItemActive,
  onClickItemBtn,
}) => {
  const classes = useStyles();

  return (
    <Box className={`${classes.manageDialogSwapBox} manage-dialog-swap-box`}>
      <Box
        className="manage-dialog-swap-box-sub"
        sx={{ width: '466px', display: 'flex' }}
      >
        <Box sx={{ position: 'relative' }}>
          <Button
            type="button"
            aria-label="activity"
            className={classes.manageSwapBtn}
            sx={{
              color: isFirstItemActive ? '#009DE1' : '#939598',
              '&.Mui-disabled': {
                color: isFirstItemActive ? '#009DE1' : '#939598',
              },
            }}
            onClick={() => {
              onClickItemBtn(true);
            }}
            disabled={
              //   isCallingManageUpdateApi ||
              //   !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPDEVICEMODAL?.includes(
              //     FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPDEVICEMODAL.VIEWLIST,
              //   )
              false
            }
            disableRipple
          >
            <Typography
              sx={{
                fontWeight: isFirstItemActive ? '700' : '500',
              }}
              className={classes.manageSwapBtnText}
            >
              {firstTitle}
            </Typography>
          </Button>

          {isFirstItemActive && (
            // eslint-disable-next-line react/self-closing-comp
            <hr
              //   style={{
              //     width:
              //       accountInfo.locale === LOCALE_CODE.JA
              //         ? '221px'
              //         : accountInfo.locale === LOCALE_CODE.FR
              //         ? isSafari
              //           ? '322px'
              //           : '293px'
              //         : accountInfo.locale === LOCALE_CODE.DE
              //         ? isSafari
              //           ? '285px'
              //           : '262px'
              //         : '242px',
              //   }}
              style={{ width: '100%' }}
              className={classes.activeBar}
            ></hr>
          )}
        </Box>
        <Box sx={{ position: 'relative' }}>
          <Button
            type="button"
            aria-label="activity"
            className={classes.manageSwapBtn}
            sx={{
              color: !isFirstItemActive ? '#009DE1' : '#939598',
              '&.Mui-disabled': {
                color: !isFirstItemActive ? '#009DE1' : '#939598',
              },
            }}
            onClick={() => {
              onClickItemBtn(false);
            }}
            disabled={
              //   isCallingManageUpdateApi ||
              //   !accountInfo.featurePermissions.GROUPMANAGEMENT?.GROUPUSERMODAL?.includes(
              //     FEATURE_PERMISSION.GROUPMANAGEMENT.GROUPUSERMODAL.VIEWLIST,
              //   )
              false
            }
            disableRipple
          >
            <Typography
              sx={{
                fontWeight: !isFirstItemActive ? '700' : '500',
              }}
              className={classes.manageSwapBtnText}
            >
              {secondTitle}
            </Typography>
          </Button>

          {!isFirstItemActive && (
            // eslint-disable-next-line react/self-closing-comp
            <hr
              //   style={{
              //     width:
              //       accountInfo.locale === LOCALE_CODE.JA
              //         ? '256px'
              //         : accountInfo.locale === LOCALE_CODE.FR
              //         ? isSafari
              //           ? '342px'
              //           : '310px'
              //         : accountInfo.locale === LOCALE_CODE.DE
              //         ? isSafari
              //           ? '310px'
              //           : '285px'
              //         : '224px',
              //   }}
              style={{ width: '100%' }}
              className={`${classes.activeBar} manage-dialog-active-bar`}
            ></hr>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default DialogSwapBox;
