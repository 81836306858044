import { AxiosError } from 'axios';
import callApiUtil from '../utils/callApiUtil';
import { API_URL, SERVER_MESSAGE } from '../constants/constants';

export const UrlList = {
  TransHistory: {
    url: API_URL.TRANS_HISTORY_LIST_DOWNLOAD_API,
    fileName: 'TranslationHistoryList.csv',
  },
  TransCount: {
    url: API_URL.TRANS_COUNT_LIST_DOWNLOAD_API,
    fileName: 'TranslationCountList.csv',
  },
  TransFrom: {
    url: API_URL.FROM_LANG_LIST_DOWNLOAD_API,
    fileName: 'TranslationFromList.csv',
  },
  TransTo: {
    url: API_URL.TO_LANG_LIST_DOWNLOAD_API,
    fileName: 'TranslationToList.csv',
  },
};

type responseDownload = {
  data: {
    message: string;
  };
};

const callReportDownload = (
  corpId: string,
  from: string,
  to: string,
  view: string,
  src: { url: string; fileName: string },
  imei?: string,
) => {
  const imeiAdd =
    imei && src.url === UrlList.TransHistory.url ? `&imei=${imei}` : '';
  const response = callApiUtil
    .get(
      `${src.url}?corpId=${corpId}&from=${from}&to=${to}&view=${view}${imeiAdd}`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    .then((apiResponse) => {
      if (apiResponse.status === 200) {
        const type = apiResponse.headers['content-type'];
        const blob = new Blob([apiResponse.data], {
          type,
        });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = src.fileName;
        link.click();

        return {
          message: SERVER_MESSAGE.FETCH_OK,
        };
      }
      const apiResponseDenied = apiResponse as responseDownload;
      if (apiResponseDenied?.data && apiResponseDenied?.data?.message) {
        return {
          message: apiResponseDenied?.data?.message,
        };
      }
      throw SERVER_MESSAGE.ERR_UNKNOWN;
    })
    .catch((error: AxiosError) => ({ message: error }));

  return response;
};

export default callReportDownload;
