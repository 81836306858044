import { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Box, Button, InputBase, Tooltip, Typography } from '@mui/material';
import { useStyles } from '../Templates/CommonStyle';
// assets
import { ReactComponent as DeleteIcon } from '../../images/icon/delete.svg';
import { ReactComponent as CaretIcon } from '../../images/icon/caret-down.svg';
import { ReactComponent as CaretIconDown } from '../../images/icon/caret-down-original.svg';
import {
  SSID_VALIDATION,
  NETWORK_PREFIX,
  IP_ADDRESS_VALIDATION,
  IP_PORT_VALIDATION,
  BYPASS_PROXY_VALIDATION,
  PROXY_NAME_VALIDATION,
  WIFI_PASSWORD_PATTERN,
  FEATURE_PERMISSION,
  CA_USER_PASSWORD_PATTERN,
} from '../../constants/constants';
import useSwitchLocaleLanguage from '../../hooks/useSwitchLocaleLanguage';
import useAccountInfo from '../../hooks/useAccountInfo';
import InputPassword from '../Atoms/InputPassword';
import CommonSelect from '../Atoms/CommonSelect';
import DropFileUpload from '../Atoms/DropFIleUpload';
import { sendGAEvent } from '../../hooks/useGAEvent';
import { LOCALE_CODE } from '../../hooks/useLocale';

/*eslint-disable*/
type IProps = {
  id: number;
  field: any;
  register: any;
  index: number;
  errors: any;
  control: any;
  deleteHandler: any;
  setValue: any;
  checkSaveButtonDisable: any;
  formField: any;
  setError: any;
  isGeneralSettingsActive: boolean;
  validateInput: any;
};

const WifiSettingsCard = ({
  id,
  field,
  register,
  index,
  errors,
  control,
  deleteHandler,
  setValue,
  checkSaveButtonDisable,
  formField,
  setError,
  isGeneralSettingsActive,
  validateInput,
}: IProps): JSX.Element => {
  const [securityType, setSecurityType] = useState<number>(
    isGeneralSettingsActive ? field.securityType : 3,
  );
  const [eapMethod, setEapMethod] = useState<number>(
    field?.eapMethod?.eapType ? field?.eapMethod?.eapType : 0,
  );
  const [caCertificate, setCaCertificate] = useState<File>(
    field?.caCertificate,
  );
  const [userCertificate, setUserCertificate] = useState<File>(
    field?.userCertificate,
  );
  const [advanceOpt, setAdvanceOpt] = useState<boolean>(field.advanceOpt);
  const [proxyType, setProxyType] = useState<number>(field.proxyType);
  const [wifiIPSettingType, setWifiIPSettingType] = useState<number>(
    field.wifiIPSettingType,
  );
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { t } = useSwitchLocaleLanguage();
  const classes = useStyles();
  const { accountInfo } = useAccountInfo();

  const securityOpt = [
    { value: 0, label: t('remoteDevice.securityOpt.none') },
    { value: 1, label: t('remoteDevice.securityOpt.wep') },
    { value: 2, label: t('remoteDevice.securityOpt.wpa') },
  ];

  const eapMethods = [
    { value: 0, label: t('remoteDevice.eapMethods.peap') },
    { value: 1, label: t('remoteDevice.eapMethods.tls') },
  ];

  const proxyMenuOpt = [
    { value: 0, label: t('remoteDevice.proxyMenuOpt.none') },
    { value: 1, label: t('remoteDevice.proxyMenuOpt.manual') },
    { value: 2, label: t('remoteDevice.proxyMenuOpt.proxyAutoConfig') },
  ];

  const ipAddressOpt = [
    { value: 0, label: t('remoteDevice.ipAddressOpt.dHCP') },
    { value: 1, label: t('remoteDevice.ipAddressOpt.static') },
  ];

  const customStyles = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      background: '#fff',
      minHeight: '34px',
      height: '34px',
      borderRadius: '3px',
      boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
      border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        borderColor: '#939598',
      },
    }),

    valueContainer: (provided: any, state: any) => ({
      ...provided,
      height: '34px',
      padding: '0 8px',
      marginTop: '-1px',
    }),

    input: (provided: any, state: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: (state: any) => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any, state: any) => ({
      ...provided,
      height: '34px',
      marginTop: '-1px',
    }),
    menu: (provided: any, state: any) => ({
      ...provided,
      borderRadius: '3px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      height: '35px',
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      color: '#313133',
      ':active': {
        backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      },
      ':first-child': {
        borderTopRightRadius: '3px',
        borderTopLeftRadius: '3px',
      },
      ':last-child': {
        borderBottomRightRadius: '3px',
        borderBottomLeftRadius: '3px',
      },
    }),
  };

  const EditAccessForWifi =
    accountInfo.featurePermissions.GROUPMANAGEMENT?.REMOTECONTROLWIFI.includes(
      FEATURE_PERMISSION.GROUPMANAGEMENT.REMOTECONTROLWIFI.EDIT,
    );

  const handleSecurityChange = (value: number) => {
    setValue(`wifi_settings.${index}.securityType`, value);
    checkSaveButtonDisable();
    setSecurityType(value);
  };

  const handleEapMethodChange = (value: number) => {
    if (value !== eapMethod) {
      try {
        if (value !== formField.wifiSettings[index].eapMethod.eapType) {
          setValue(
            `wifi_settings.${index}.eapMethod.${
              eapMethod === 0 ? 'peap' : 'tls'
            }.caCertificateFileName`,
            '',
          );
          setValue(
            `wifi_settings.${index}.eapMethod.${
              eapMethod === 0 ? 'peap' : 'tls'
            }.identity`,
            '',
          );
          setValue(
            `wifi_settings.${index}.eapMethod.${
              eapMethod === 0 ? 'peap' : 'tls'
            }.caDomainName`,
            '',
          );

          setValue(
            `wifi_settings.${index}.eapMethod.${value === 1 ? 'peap' : 'tls'}`,
            null,
          );
        } else {
          setValue(
            `wifi_settings.${index}.eapMethod.${
              eapMethod === 0 ? 'peap' : 'tls'
            }.identity`,
            eapMethod === 0
              ? formField.wifiSettings[index].eapMethod?.peap?.identity
              : formField.wifiSettings[index].eapMethod?.tls?.identity,
          );
          setValue(
            `wifi_settings.${index}.eapMethod.${
              eapMethod === 0 ? 'peap' : 'tls'
            }.caDomainName`,
            eapMethod === 0
              ? formField.wifiSettings[index].eapMethod?.peap?.caDomainName
              : formField.wifiSettings[index].eapMethod?.tls?.caDomainName,
          );

          setValue(
            `wifi_settings.${index}.eapMethod.${value === 0 ? 'peap' : 'tls'}`,
            value === 0
              ? formField.wifiSettings[index].eapMethod?.peap
              : formField.wifiSettings[index].eapMethod?.tls,
          );
        }
      } catch (err) {
        setValue(
          `wifi_settings.${index}.eapMethod.${
            eapMethod === 0 ? 'peap' : 'tls'
          }.caCertificateFileName`,
          '',
        );
        setValue(
          `wifi_settings.${index}.eapMethod.${
            eapMethod === 0 ? 'peap' : 'tls'
          }.identity`,
          '',
        );
        setValue(
          `wifi_settings.${index}.eapMethod.${
            eapMethod === 0 ? 'peap' : 'tls'
          }.caDomainName`,
          '',
        );
        setValue(
          `wifi_settings.${index}.eapMethod.${value === 1 ? 'peap' : 'tls'}`,
          null,
        );
      }

      setEapMethod(value);
      checkSaveButtonDisable();
    }
  };

  const handleAdvanceOptChange = (value: boolean) => {
    setAdvanceOpt(value);
  };

  const handleProxyTypeChange = (value: number) => {
    setValue(`wifi_settings.${index}.proxyType`, value);
    checkSaveButtonDisable();
    setProxyType(value);
  };

  const handleWifiIPSettingTypeChange = (value: number) => {
    setValue(`wifi_settings.${index}.wifiIPSettingType`, value);
    checkSaveButtonDisable();
    setWifiIPSettingType(value);
  };

  return (
    <div
      key={field.id + index}
      className={`${classes.newWifiBox} ${'remove-device-setup-new-wifi-box'}`}
    >
      <Box
        className="remove-device-setup-new-wifi-sub"
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <Box
          className={`${classes.remoteDialogInnerWrapper} ${
            classes.gridCol3
          } ${'remove-device-setup-new-wifi-inner'}`}
        >
          {/* SSID */}
          <Box
            className="remove-device-setup-wifi-setting"
            sx={{
              gridColumn: isGeneralSettingsActive ? '' : 'span 2',
              width: isGeneralSettingsActive ? '200px' : '100%',
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <label className={classes.customInputLabel}>
                  {t('remoteDevice.label.netSSD')}
                </label>

                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              {screenWidth < 900 && (
                <Box
                  sx={{
                    minWidth: '24px',
                    paddingLeft: '8px',
                    alignSelf: 'flex-start',
                    paddingBottom: '5px',
                  }}
                >
                  {/* Delete Button */}
                  <Tooltip
                    PopperProps={{
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: [0, -9],
                          },
                        },
                      ],
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          minWidth: '58px',
                          height: '26px',
                          textAlign: 'center',
                          fontWeight: 300,
                          fontSize: '12px',
                        },
                      },
                    }}
                    /*eslint-disable*/
                    title={t('remoteDevice.tooltip.delete') as string}
                    placement="top"
                    arrow
                  >
                    <DeleteIcon
                      className={classes.deleteIcon}
                      onClick={() => {
                        EditAccessForWifi && deleteHandler(index);
                      }}
                    />
                  </Tooltip>
                </Box>
              )}
            </Box>

            <Controller
              control={control}
              name={`wifi_settings.${index}.ssid`}
              defaultValue={field.ssid}
              rules={{
                required: {
                  value: true,
                  message: t('common.validationError.required'),
                },
                maxLength: {
                  value: 32,
                  message: t('remoteDevice.message.maxChar'),
                },
                pattern: {
                  value: SSID_VALIDATION,
                  message: t('remoteDevice.message.minChar'),
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputBase
                  key={field.id}
                  inputProps={{
                    maxLength: 33,
                  }}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    validateInput(
                      `wifi_settings.${index}.ssid`,
                      e.target.value,
                      {
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        maxLength: {
                          value: 32,
                          message: t('remoteDevice.message.maxChar'),
                        },
                        pattern: {
                          value: SSID_VALIDATION,
                          message: t('remoteDevice.message.minChar'),
                        },
                      },
                    );
                    checkSaveButtonDisable();
                  }}
                  placeholder={t('remoteDevice.placeholder.netName')}
                  className={`${classes.customInputOutline} ${
                    errors.wifi_settings?.[index]?.ssid &&
                    classes.customInputOutlineError
                  }`}
                  disabled={!EditAccessForWifi}
                />
              )}
            />
            {errors.wifi_settings?.[index]?.ssid && (
              <Box className={classes.newWifiErrorCard}>
                <p style={{ padding: '0', margin: '0' }}>
                  {/* Network Name/SSID Required. */}
                  {errors.wifi_settings?.[index]?.ssid?.message!}
                </p>
              </Box>
            )}
          </Box>
          {/* Password */}
          {isGeneralSettingsActive && (
            <Box
              className="remove-device-setup-wifi-setting"
              sx={{ width: '200px', position: 'relative' }}
            >
              <Box sx={{ display: 'flex' }}>
                <label className={classes.customInputLabel}>
                  {t('remoteDevice.label.password')}
                </label>
                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              <Controller
                control={control}
                name={`wifi_settings.${index}.password`}
                defaultValue={field.password}
                rules={{
                  required: {
                    value: true,
                    message: t('common.validationError.required'),
                  },
                  minLength: {
                    value: 8,
                    message: t('remoteDevice.message.leastPassChar'),
                  },
                  maxLength: {
                    value: 64,
                    message: t('remoteDevice.message.maxChar64'),
                  },
                  pattern: {
                    value: WIFI_PASSWORD_PATTERN,
                    message: t('remoteDevice.message.validPassword'),
                  },
                }}
                render={({ field: { onChange, value } }) => (
                  <InputPassword
                    type="text"
                    {...field}
                    inputProps={{
                      maxLength: 65,
                    }}
                    value={value}
                    onChange={(e) => {
                      setValue(
                        `wifi_settings.${index}.password`,
                        e.target.value,
                      );
                      onChange(e);
                      validateInput(
                        `wifi_settings.${index}.password`,
                        e.target.value,
                        {
                          required: {
                            value: true,
                            message: t('common.validationError.required'),
                          },
                          minLength: {
                            value: 8,
                            message: t('remoteDevice.message.leastPassChar'),
                          },
                          maxLength: {
                            value: 64,
                            message: t('remoteDevice.message.maxChar64'),
                          },
                          pattern: {
                            value: WIFI_PASSWORD_PATTERN,
                            message: t('remoteDevice.message.validPassword'),
                          },
                        },
                      );
                      checkSaveButtonDisable();
                    }}
                    placeholder={t('remoteDevice.label.password')}
                    color={
                      errors.wifi_settings?.[index]?.password
                        ? 'error'
                        : 'primary'
                    }
                    disabled={!EditAccessForWifi}
                  />
                )}
              />
              {errors.wifi_settings?.[index]?.password && (
                <Box className={classes.newWifiErrorCard}>
                  <p style={{ padding: '0', margin: '0' }}>
                    {errors.wifi_settings?.[index]?.password?.message!}
                  </p>
                </Box>
              )}
            </Box>
          )}
          {/* ID */}
          <Box
            sx={{
              width: '20px',
              position: 'relative',
              display: 'none',
            }}
          >
            <Controller
              control={control}
              name={`wifi_settings.${index}.id`}
              defaultValue={field.id ? field.id : 0}
              render={({ field: { onChange, value } }) => (
                <InputPassword
                  type="text"
                  {...field}
                  value={value}
                  onChange={onChange}
                  disabled={!EditAccessForWifi}
                />
              )}
            />
          </Box>
          {/* Security */}
          <Box
            className="remove-device-setup-wifi-setting"
            sx={{ width: '200px' }}
          >
            <label
              className={classes.customInputLabel}
              style={{ display: 'flex' }}
            >
              {t('remoteDevice.label.security')}
            </label>
            {isGeneralSettingsActive ? (
              <CommonSelect
                ControllerName={`wifi_settings.${index}.securityType`}
                control={control}
                options={securityOpt}
                isSearchable={false}
                className={classes.reactSelect}
                classNamePrefix="react-select"
                styles={customStyles}
                isDisabled={!EditAccessForWifi}
                onChangeValue={handleSecurityChange}
              />
            ) : (
              <Controller
                control={control}
                name={`wifi_settings.${index}.securityType`}
                defaultValue={t(`remoteDevice.securityOpt.802.1xEAP`)}
                render={() => (
                  <InputBase
                    key={field.id}
                    inputProps={{
                      maxLength: 33,
                    }}
                    value={t(`remoteDevice.securityOpt.802.1xEAP`)}
                    className={classes.customInputOutline}
                    disabled={true}
                  />
                )}
              />
            )}
            {/* <p className={classes.inputText} style={{ position: 'absolute' }}>
              {t('remoteDevice.message.securityDropDownMessage')}
            </p> */}
          </Box>
        </Box>
        {screenWidth >= 900 && (
          <Box
            sx={{
              minWidth: '24px',
              paddingLeft: '8px',
              alignSelf: 'flex-start',
              paddingBottom: '5px',
              '@media (max-width: 900px)': {
                marginTop: '35px',
                paddingLeft: '0px',
              },
            }}
          >
            {/* Delete Button */}
            <Tooltip
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -9],
                    },
                  },
                ],
              }}
              componentsProps={{
                tooltip: {
                  sx: {
                    minWidth: '58px',
                    height: '26px',
                    textAlign: 'center',
                    fontWeight: 300,
                    fontSize: '12px',
                  },
                },
              }}
              /*eslint-disable*/
              title={t('remoteDevice.tooltip.delete') as string}
              placement="top"
              arrow
            >
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={() => {
                  EditAccessForWifi && deleteHandler(index);
                }}
              />
            </Tooltip>
          </Box>
        )}
      </Box>

      {/* securityType value 3 === '802.1xEAP' */}
      {securityType === 3 && (
        <>
          <Box
            className="remove-device-setup-new-wifi-sub"
            sx={{
              display: 'flex',
              justifyContent: 'space-beetween',
            }}
          >
            <Box
              className={`${classes.remoteDialogInnerWrapper} ${
                classes.gridCol3
              } ${'remove-device-setup-new-wifi-inner'}`}
            >
              {/* eap */}
              <Box
                className="remove-device-setup-wifi-setting"
                sx={{
                  width: '200px',
                  position: 'relative',
                  '@media screen and (max-width: 899px)': {
                    paddingTop: '26px',
                  },
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <label className={classes.customInputLabel}>
                    {t('remoteDevice.label.eapMethod')}
                  </label>
                </Box>
                <CommonSelect
                  ControllerName={`wifi_settings.${index}.eapMethod.eapType`}
                  control={control}
                  options={eapMethods}
                  isSearchable={false}
                  className={classes.reactSelect}
                  classNamePrefix="react-select"
                  styles={customStyles}
                  isDisabled={!EditAccessForWifi}
                  customValue={0}
                  onChangeValue={handleEapMethodChange}
                />
              </Box>
              {/* Phase2 Authentication */}
              {/* eapMethod value 0 === PEAP */}
              {eapMethod === 0 && (
                <>
                  <Box
                    className="remove-device-setup-wifi-setting"
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.phase2Auth')}
                      </label>
                    </Box>
                    <InputBase
                      key={field.id}
                      {...register(
                        `wifi_settings.${index}.phase2Auth` as const,
                      )}
                      value={'MSCHAPV2'}
                      inputProps={{
                        maxLength: 33,
                      }}
                      className={`${classes.customInputOutline} ${
                        errors.wifi_settings?.[index]?.phase2Auth &&
                        classes.customInputOutlineError
                      }`}
                      disabled={true}
                    />
                  </Box>
                </>
              )}

              {/* ID */}
              <Box
                sx={{
                  width: '20px',
                  position: 'relative',
                  display: 'none',
                }}
              >
                <Controller
                  control={control}
                  name={`wifi_settings.${index}.id`}
                  defaultValue={field.id ? field.id : 0}
                  render={({ field: { onChange, value } }) => (
                    <InputPassword
                      type="text"
                      {...field}
                      value={value}
                      onChange={onChange}
                      disabled={!EditAccessForWifi}
                    />
                  )}
                />
              </Box>
            </Box>
          </Box>
          {/* crt_file upload */}
          <Box
            className="remove-device-setup-wifi-setting"
            sx={{
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', paddingTop: '12px' }}>
              <label className={classes.customInputLabel}>
                {t('remoteDevice.label.caCertification')}
              </label>
              <Typography className={classes.loginCardLevelMarker}>
                *
              </Typography>
            </Box>

            {eapMethod === 0 ? (
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={field.eapMethod?.peap?.caCertificateFileName}
                name={`wifi_settings.${index}.eapMethod.peap.caCertificateFileName`}
                render={({ field: { onChange, value } }) => (
                  <DropFileUpload
                    onChange={(e) => {
                      if (
                        e !== null &&
                        typeof e === 'object' &&
                        'base64File' in e &&
                        'name' in e
                      ) {
                        setValue(
                          `wifi_settings.${index}.eapMethod.peap.caCertificateFileName`,
                          e.name,
                        );

                        setValue(
                          `wifi_settings.${index}.eapMethod.peap.caCertificateContent`,
                          e.base64File,
                        );

                        checkSaveButtonDisable();
                      }
                    }}
                    defValue={{
                      name: field.eapMethod?.peap?.caCertificateFileName
                        ? field.eapMethod?.peap?.caCertificateFileName
                        : '',
                      base64File: field.eapMethod?.peap?.caCertificateContent
                        ? field.eapMethod?.peap?.caCertificateContent
                        : '',
                    }}
                    base64Encode={true}
                    disabled={!EditAccessForWifi}
                    acceptedFilesTypes={['pem']}
                    title={t('remoteDevice.placeholder.dragDropFile', {
                      type: '(.pem)',
                    })}
                    titleMiddle={t('remoteDevice.placeholder.dragDropFileOr')}
                    titleBottom={t(
                      'remoteDevice.placeholder.dragDropFileBrowseFile',
                    )}
                    inputFieldMaxWidth="664px"
                    inputFieldMaxFileSize={5242880}
                    inputTextHolderMaxWidth="433px"
                    multipleFilesError={t(
                      'remoteDevice.message.dropFileUploadMultiErr',
                    )}
                    fileTypeError={t('remoteDevice.message.dropFileUploadErr', {
                      type: 'pem',
                    })}
                    fileSizeError={t(
                      'remoteDevice.message.dropFileUploadSizeErr',
                      { size: '5' },
                    )}
                    isRequired={
                      errors.wifi_settings?.[index]?.eapMethod?.peap
                        ?.caCertificateFileName
                    }
                    requiredMessage={t(
                      'remoteDevice.message.dropFileUploadRequired',
                    )}
                    name={'Ca Certification PEAP'}
                  />
                )}
              />
            ) : (
              <Controller
                control={control}
                rules={{ required: true }}
                defaultValue={field.eapMethod?.tls?.caCertificateFileName}
                name={`wifi_settings.${index}.eapMethod.tls.caCertificateFileName`}
                render={({ field: { onChange, value } }) => (
                  <DropFileUpload
                    onChange={(e) => {
                      if (
                        e !== null &&
                        typeof e === 'object' &&
                        'base64File' in e &&
                        'name' in e
                      ) {
                        setValue(
                          `wifi_settings.${index}.eapMethod.tls.caCertificateFileName`,
                          e.name,
                        );

                        setValue(
                          `wifi_settings.${index}.eapMethod.tls.caCertificateContent`,
                          e.base64File,
                        );

                        checkSaveButtonDisable();
                      }
                    }}
                    defValue={{
                      name: field.eapMethod?.tls?.caCertificateFileName
                        ? field.eapMethod?.tls?.caCertificateFileName
                        : '',
                      base64File: field.eapMethod?.tls?.caCertificateContent
                        ? field.eapMethod?.tls?.caCertificateContent
                        : '',
                    }}
                    base64Encode={true}
                    disabled={!EditAccessForWifi}
                    acceptedFilesTypes={['pem']}
                    title={t('remoteDevice.placeholder.dragDropFile', {
                      type: '(.pem)',
                    })}
                    titleMiddle={t('remoteDevice.placeholder.dragDropFileOr')}
                    titleBottom={t(
                      'remoteDevice.placeholder.dragDropFileBrowseFile',
                    )}
                    inputFieldMaxWidth="664px"
                    inputFieldMaxFileSize={5242880}
                    inputTextHolderMaxWidth="433px"
                    multipleFilesError={t(
                      'remoteDevice.message.dropFileUploadMultiErr',
                    )}
                    fileTypeError={t('remoteDevice.message.dropFileUploadErr', {
                      type: 'pem',
                    })}
                    fileSizeError={t(
                      'remoteDevice.message.dropFileUploadSizeErr',
                      { size: '5' },
                    )}
                    isRequired={
                      errors.wifi_settings?.[index]?.eapMethod?.tls
                        ?.caCertificateFileName
                    }
                    requiredMessage={t(
                      'remoteDevice.message.dropFileUploadRequired',
                    )}
                    name={'Ca Certification TLS'}
                  />
                )}
              />
            )}
          </Box>
          <Box
            className="remove-device-setup-new-wifi-sub"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Box
              className={`${classes.remoteDialogInnerWrapper} ${
                classes.gridCol3
              } ${'remove-device-setup-new-wifi-inner'}`}
              sx={{
                gap:
                  accountInfo.locale === LOCALE_CODE.FR
                    ? '17px !important'
                    : '',
              }}
            >
              {/* caDomainName */}
              <Box
                className="remove-device-setup-wifi-setting"
                sx={{ width: '200px', position: 'relative' }}
              >
                <Box sx={{ display: 'flex' }}>
                  <label className={classes.customInputLabel}>
                    {t('remoteDevice.label.caDomainName')}
                  </label>

                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name={`wifi_settings.${index}.eapMethod.${
                    eapMethod === 0 ? 'peap' : 'tls'
                  }.caDomainName`}
                  defaultValue={
                    eapMethod === 0
                      ? field.eapMethod?.peap?.caDomainName
                      : field.eapMethod?.tls?.caDomainName
                  }
                  rules={{
                    required: {
                      value: true,
                      message: t('common.validationError.required'),
                    },
                    maxLength: {
                      value: 1000,
                      message: t('remoteDevice.message.maxChar1000'),
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputBase
                      inputProps={{
                        maxLength: 1001,
                        autoComplete: 'new-password',
                        form: {
                          autoComplete: 'new-password',
                        },
                      }}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        validateInput(
                          `wifi_settings.${index}.eapMethod.${
                            eapMethod === 0 ? 'peap' : 'tls'
                          }.caDomainName`,
                          e.target.value,
                          {
                            required: {
                              value: true,
                              message: t('common.validationError.required'),
                            },
                            maxLength: {
                              value: 1000,
                              message: t('remoteDevice.message.maxChar1000'),
                            },
                          },
                        );
                        checkSaveButtonDisable();
                      }}
                      placeholder={t('remoteDevice.placeholder.caDomainName')}
                      className={`${classes.customInputOutline} ${
                        error && classes.customInputOutlineError
                      }`}
                      disabled={!EditAccessForWifi}
                    />
                  )}
                />
                {(eapMethod === 0
                  ? errors.wifi_settings?.[index]?.eapMethod?.peap?.caDomainName
                  : errors.wifi_settings?.[index]?.eapMethod?.tls
                      ?.caDomainName) && (
                  <Box className={classes.newWifiErrorCard}>
                    <p style={{ padding: '0', margin: '0' }}>
                      {/* Network caDomainName Required. */}
                      {eapMethod === 0
                        ? errors.wifi_settings?.[index]?.eapMethod?.peap
                            ?.caDomainName?.message!
                        : errors.wifi_settings?.[index]?.eapMethod?.tls
                            ?.caDomainName?.message!}
                    </p>
                  </Box>
                )}
              </Box>
              {/* caUserPassword */}
              {/* eapMethod value 0 === PEAP */}
              {eapMethod === 0 && (
                <>
                  <Box
                    className="remove-device-setup-wifi-setting"
                    sx={{
                      width:
                        accountInfo.locale === LOCALE_CODE.FR
                          ? '230px'
                          : '200px',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.caUserPassword')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.eapMethod.peap.caUserPassword`}
                      defaultValue={field.eapMethod?.peap?.caUserPassword}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        minLength: {
                          value: 8,
                          message: t('remoteDevice.message.leastPassChar'),
                        },
                        maxLength: {
                          value: 64,
                          message: t('remoteDevice.message.maxChar64'),
                        },
                        pattern: {
                          value: CA_USER_PASSWORD_PATTERN,
                          message: t('remoteDevice.message.validPassword'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputPassword
                          type="text"
                          {...field}
                          inputProps={{
                            maxLength: 65,
                            autoComplete: 'new-password',
                            form: {
                              autoComplete: 'new-password',
                            },
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            validateInput(
                              `wifi_settings.${index}.eapMethod.peap.caUserPassword`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                minLength: {
                                  value: 8,
                                  message: t(
                                    'remoteDevice.message.leastPassChar',
                                  ),
                                },
                                maxLength: {
                                  value: 64,
                                  message: t('remoteDevice.message.maxChar64'),
                                },
                                pattern: {
                                  value: CA_USER_PASSWORD_PATTERN,
                                  message: t(
                                    'remoteDevice.message.validPassword',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder={t('remoteDevice.label.caUserPassword')}
                          color={
                            errors.wifi_settings?.[index]?.eapMethod?.peap
                              ?.caUserPassword
                              ? 'error'
                              : 'primary'
                          }
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.eapMethod?.peap
                      ?.caUserPassword && (
                      <Box className={classes.newWifiErrorCard}>
                        <p
                          style={{
                            padding: '0',
                            margin: '0',
                          }}
                        >
                          {
                            errors.wifi_settings?.[index]?.eapMethod?.peap
                              ?.caUserPassword?.message!
                          }
                        </p>
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {/* Identity */}
              <Box
                className="remove-device-setup-wifi-setting"
                sx={{
                  width: '200px',
                  position: 'relative',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <label className={classes.customInputLabel}>
                    {t('remoteDevice.label.identity')}
                  </label>

                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>
                <Controller
                  control={control}
                  name={`wifi_settings.${index}.eapMethod.${
                    eapMethod === 0 ? 'peap' : 'tls'
                  }.identity`}
                  defaultValue={
                    eapMethod === 0
                      ? field.eapMethod?.peap?.identity
                      : field.eapMethod?.tls?.identity
                  }
                  rules={{
                    required: {
                      value: true,
                      message: t('common.validationError.required'),
                    },
                    maxLength: {
                      value: 1000,
                      message: t('remoteDevice.message.maxChar1000'),
                    },
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <InputBase
                      inputProps={{
                        maxLength: 1001,
                      }}
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        validateInput(
                          `wifi_settings.${index}.eapMethod.${
                            eapMethod === 0 ? 'peap' : 'tls'
                          }.identity`,
                          e.target.value,
                          {
                            required: {
                              value: true,
                              message: t('common.validationError.required'),
                            },
                            maxLength: {
                              value: 1000,
                              message: t('remoteDevice.message.maxChar1000'),
                            },
                          },
                        );
                        checkSaveButtonDisable();
                      }}
                      placeholder={t('remoteDevice.placeholder.identity')}
                      className={`${classes.customInputOutline} ${
                        error && classes.customInputOutlineError
                      }`}
                      disabled={!EditAccessForWifi}
                    />
                  )}
                />
                {(eapMethod === 0
                  ? errors.wifi_settings?.[index]?.eapMethod?.peap?.identity
                  : errors.wifi_settings?.[index]?.eapMethod?.tls
                      ?.identity) && (
                  <Box className={classes.newWifiErrorCard}>
                    <p style={{ padding: '0', margin: '0' }}>
                      {/* Network identity Required. */}
                      {eapMethod === 0
                        ? errors.wifi_settings?.[index]?.eapMethod?.peap
                            ?.identity?.message!
                        : errors.wifi_settings?.[index]?.eapMethod?.tls
                            ?.identity?.message!}
                    </p>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/* eapMethod value 0 === PEAP */}
          {eapMethod === 0 && (
            <>
              <Box
                className="remove-device-setup-new-wifi-sub"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box
                  className={`${classes.remoteDialogInnerWrapper} ${
                    classes.gridCol3
                  } ${'remove-device-setup-new-wifi-inner'}`}
                >
                  {/* anonymous identity */}
                  <Box
                    className="remove-device-setup-wifi-setting"
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.anonymousIdentity')}
                      </label>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.eapMethod.peap.anonymousIdentity`}
                      defaultValue={field.eapMethod?.peap?.anonymousIdentity}
                      rules={{
                        maxLength: {
                          value: 1000,
                          message: t('remoteDevice.message.maxChar1000'),
                        },
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 1001,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            validateInput(
                              `wifi_settings.${index}.eapMethod.peap.anonymousIdentity`,
                              e.target.value,
                              {
                                maxLength: {
                                  value: 1000,
                                  message: t(
                                    'remoteDevice.message.maxChar1000',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder={t(
                            'remoteDevice.placeholder.anonymousIdentity',
                          )}
                          className={`${classes.customInputOutline} ${
                            error && classes.customInputOutlineError
                          }`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.eapMethod?.peap
                      ?.anonymousIdentity && (
                      <Box className={classes.newWifiErrorCard}>
                        <p
                          style={{
                            padding: '0',
                            margin: '0',
                          }}
                        >
                          {/* Network anonymousIdentity Required. */}
                          {
                            errors.wifi_settings?.[index]?.eapMethod?.peap
                              ?.anonymousIdentity?.message!
                          }
                        </p>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
          {eapMethod === 1 && (
            <>
              {/* user certification file upload */}
              <Box
                className="remove-device-setup-wifi-setting"
                sx={{
                  position: 'relative',
                }}
              >
                <Box sx={{ display: 'flex', paddingTop: '12px' }}>
                  <label className={classes.customInputLabel}>
                    {t('remoteDevice.label.userCertification')}
                  </label>
                  <Typography className={classes.loginCardLevelMarker}>
                    *
                  </Typography>
                </Box>

                <Controller
                  control={control}
                  rules={{ required: true }}
                  defaultValue={field.eapMethod?.tls?.userCertificateFileName}
                  name={`wifi_settings.${index}.eapMethod.tls.userCertificateFileName`}
                  render={({ field: { onChange, value } }) => (
                    <DropFileUpload
                      onChange={(e) => {
                        if (
                          e !== null &&
                          typeof e === 'object' &&
                          'base64File' in e &&
                          'name' in e
                        ) {
                          setValue(
                            `wifi_settings.${index}.eapMethod.tls.userCertificateFileName`,
                            e.name,
                          );

                          setValue(
                            `wifi_settings.${index}.eapMethod.tls.userCertificateContent`,
                            e.base64File,
                          );

                          checkSaveButtonDisable();
                        }
                      }}
                      defValue={{
                        name: field.eapMethod?.tls?.userCertificateFileName
                          ? field.eapMethod?.tls?.userCertificateFileName
                          : '',
                        base64File: field.eapMethod?.tls?.userCertificateContent
                          ? field.eapMethod?.tls?.userCertificateContent
                          : '',
                      }}
                      base64Encode={true}
                      disabled={!EditAccessForWifi}
                      acceptedFilesTypes={['p12']}
                      title={t('remoteDevice.placeholder.dragDropFile', {
                        type: '(.p12)',
                      })}
                      titleMiddle={t('remoteDevice.placeholder.dragDropFileOr')}
                      titleBottom={t(
                        'remoteDevice.placeholder.dragDropFileBrowseFile',
                      )}
                      inputFieldMaxWidth="664px"
                      inputFieldMaxFileSize={5242880}
                      inputTextHolderMaxWidth="433px"
                      multipleFilesError={t(
                        'remoteDevice.message.dropFileUploadMultiErr',
                      )}
                      fileTypeError={t(
                        'remoteDevice.message.dropFileUploadErr',
                        {
                          type: 'p12',
                        },
                      )}
                      fileSizeError={t(
                        'remoteDevice.message.dropFileUploadSizeErr',
                        { size: '5' },
                      )}
                      isRequired={
                        errors.wifi_settings?.[index]?.eapMethod?.tls
                          ?.userCertificateFileName
                      }
                      requiredMessage={t(
                        'remoteDevice.message.dropFileUploadRequired',
                      )}
                      name={'User Certification'}
                    />
                  )}
                />
              </Box>
              {/* user certification password */}
              <Box sx={{ display: 'flex', paddingTop: '12px' }}>
                <label className={classes.customInputLabel}>
                  {t('remoteDevice.label.userCertificationPassword')}
                </label>
                <Typography className={classes.loginCardLevelMarker}>
                  *
                </Typography>
              </Box>
              <Box
                className="remove-device-setup-new-wifi-sub"
                sx={{
                  display: 'flex',
                  justifyContent: 'space-beetween',
                }}
              >
                <Box
                  className={`${classes.remoteDialogInnerWrapper} ${
                    classes.gridCol3
                  } ${'remove-device-setup-new-wifi-inner'}`}
                  style={{ paddingTop: '0px' }}
                >
                  <Box
                    className="remove-device-setup-wifi-setting"
                    sx={{ position: 'relative', width: '100%' }}
                  >
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.eapMethod.tls.userCertificationExtractionPassword`}
                      defaultValue={field.userCertificationExtractionPassword}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        minLength: {
                          value: 8,
                          message: t('remoteDevice.message.leastPassChar'),
                        },
                        maxLength: {
                          value: 64,
                          message: t('remoteDevice.message.maxChar64'),
                        },
                        pattern: {
                          value: CA_USER_PASSWORD_PATTERN,
                          message: t('remoteDevice.message.validPassword'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputPassword
                          type="text"
                          {...field}
                          inputProps={{
                            maxLength: 65,
                          }}
                          value={value}
                          onChange={(e) => {
                            setValue(
                              `wifi_settings.${index}.eapMethod.tls.userCertificationExtractionPassword`,
                              e.target.value,
                            );
                            onChange(e);
                            validateInput(
                              `wifi_settings.${index}.eapMethod.tls.userCertificationExtractionPassword`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                minLength: {
                                  value: 8,
                                  message: t(
                                    'remoteDevice.message.leastPassChar',
                                  ),
                                },
                                maxLength: {
                                  value: 64,
                                  message: t('remoteDevice.message.maxChar64'),
                                },
                                pattern: {
                                  value: CA_USER_PASSWORD_PATTERN,
                                  message: t(
                                    'remoteDevice.message.validPassword',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder={t(
                            'remoteDevice.label.userCertificationExtractionPassword',
                          )}
                          color={
                            errors.wifi_settings?.[index]?.eapMethod?.tls
                              ?.userCertificationExtractionPassword
                              ? 'error'
                              : 'primary'
                          }
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.eapMethod?.tls
                      ?.userCertificationExtractionPassword && (
                      <Box className={classes.newWifiErrorCard}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {
                            errors.wifi_settings?.[index]?.eapMethod?.tls
                              ?.userCertificationExtractionPassword?.message!
                          }
                        </p>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </>
      )}

      {/* advance Option */}
      <Box
        sx={{
          '@media screen and (max-width: 899px)': {
            paddingTop: '26px',
          },
        }}
      >
        <Button
          variant="text"
          className={classes.advancedOptionsBtn}
          disableRipple
          onClick={() => {
            handleAdvanceOptChange(!advanceOpt);
            setValue(`wifi_settings.${index}.advanceOpt`, !advanceOpt);

            // GA tag
            sendGAEvent('press_btn_link', 'txt_name', 'advanced_option');
          }}
        >
          {advanceOpt ? (
            <CaretIconDown {...register(`wifi_settings.${index}.advanceOpt`)} />
          ) : (
            <CaretIcon {...register(`wifi_settings.${index}.advanceOpt`)} />
          )}
          <Typography sx={{ ml: '7px' }}>
            {t('remoteDevice.button.advancedOpt')}
          </Typography>
        </Button>
        {/* advanced options inputs */}
        {advanceOpt && (
          <Box sx={{ marginTop: '22px' }}>
            {/* proxy section start  */}
            <Box sx={{ marginBottom: '13px' }}>
              <Box
                className="remove-device-setup-wifi-setting"
                sx={{
                  width:
                    accountInfo.locale === LOCALE_CODE.FR
                      ? '300px'
                      : accountInfo.locale === LOCALE_CODE.DE
                      ? '280px'
                      : '200px',
                }}
              >
                <label className={classes.customInputLabel}>
                  {t('remoteDevice.label.proxy')}
                </label>
                <CommonSelect
                  ControllerName={`wifi_settings.${index}.proxyType`}
                  control={control}
                  options={proxyMenuOpt}
                  isSearchable={false}
                  className={classes.reactSelect}
                  classNamePrefix="react-select"
                  styles={customStyles}
                  isDisabled={!EditAccessForWifi}
                  onChangeValue={handleProxyTypeChange}
                />
              </Box>
              {/* Proxy Manual Mode */}
              {proxyType === 1 && (
                <Box>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#939598',
                      marginTop: '6px',
                    }}
                  >
                    {t('remoteDevice.label.proxyUsage')}
                  </Typography>
                  <Box
                    sx={{ marginTop: '13px' }}
                    className={`${classes.remoteDialogInnerWrapper} ${
                      classes.gridCol3
                    } ${'remove-device-setup-grid-col-1'}`}
                  >
                    {/* Proxy HostName */}
                    <Box
                      className="remove-device-setup-wifi-setting"
                      sx={{
                        position: 'relative',
                        width: '200px',
                      }}
                    >
                      <Box sx={{ display: 'flex' }}>
                        <label className={classes.customInputLabel}>
                          {t('remoteDevice.label.proxyHost')}
                        </label>
                        <Typography className={classes.loginCardLevelMarker}>
                          *
                        </Typography>
                      </Box>
                      <Controller
                        control={control}
                        name={`wifi_settings.${index}.proxyHostName`}
                        defaultValue={field.proxyHostName}
                        rules={{
                          required: {
                            value: true,
                            message: t('common.validationError.required'),
                          },
                          maxLength: {
                            value: 256,
                            message: t('remoteDevice.message.maxChar256'),
                          },
                          pattern: {
                            value: PROXY_NAME_VALIDATION,
                            message: t('remoteDevice.message.validHostname'),
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <InputBase
                            inputProps={{
                              maxLength: 257,
                            }}
                            value={value}
                            onChange={(e) => {
                              onChange(e);
                              validateInput(
                                `wifi_settings.${index}.proxyHostName`,
                                e.target.value,
                                {
                                  required: {
                                    value: true,
                                    message: t(
                                      'common.validationError.required',
                                    ),
                                  },
                                  maxLength: {
                                    value: 256,
                                    message: t(
                                      'remoteDevice.message.maxChar256',
                                    ),
                                  },
                                  pattern: {
                                    value: PROXY_NAME_VALIDATION,
                                    message: t(
                                      'remoteDevice.message.validHostname',
                                    ),
                                  },
                                },
                              );
                              checkSaveButtonDisable();
                            }}
                            placeholder={t('remoteDevice.placeholder.proxy')}
                            className={`${classes.customInputOutline} ${
                              errors.wifi_settings?.[index]?.proxyHostName &&
                              classes.customInputOutlineError
                            }`}
                            disabled={!EditAccessForWifi}
                          />
                        )}
                      />
                      {errors.wifi_settings?.[index]?.proxyHostName && (
                        <Box className={classes.newWifiErrorCard}>
                          <p
                            style={{
                              padding: '0',
                              margin: '0',
                            }}
                          >
                            {
                              errors.wifi_settings?.[index]?.proxyHostName
                                ?.message!
                            }
                          </p>
                        </Box>
                      )}
                    </Box>
                    {/* Proxy Port */}
                    <Box
                      sx={{
                        position: 'relative',
                        width: '200px',
                      }}
                      className="remove-device-setup-wifi-setting"
                    >
                      <Box sx={{ display: 'flex' }}>
                        <label className={classes.customInputLabel}>
                          {t('remoteDevice.label.proxyPort')}
                        </label>
                        <Typography className={classes.loginCardLevelMarker}>
                          *
                        </Typography>
                      </Box>
                      <Controller
                        control={control}
                        name={`wifi_settings.${index}.proxyPort`}
                        defaultValue={field.proxyPort}
                        rules={{
                          required: {
                            value: true,
                            message: t('common.validationError.required'),
                          },
                          pattern: {
                            value: IP_PORT_VALIDATION,
                            message: t('remoteDevice.message.validPort'),
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <InputBase
                            inputProps={{
                              minLength: 1,
                              maxLength: 5,
                            }}
                            value={value}
                            onChange={(e) => {
                              let inputValue = e.target.value;
                              let parsedValue = parseInt(inputValue, 10);

                              // Check if parsed value is within the range 0 to 65535
                              if (
                                isNaN(parsedValue) &&
                                parsedValue >= 0 &&
                                parsedValue <= 65535
                              ) {
                                onChange(parsedValue);
                              } else {
                                onChange(inputValue);
                                setError(`wifi_settings.${index}.proxyPort`, {
                                  type: 'manual',
                                  message: t('remoteDevice.message.validPort'),
                                });
                              }

                              validateInput(
                                `wifi_settings.${index}.proxyPort`,
                                e.target.value,
                                {
                                  required: {
                                    value: true,
                                    message: t(
                                      'common.validationError.required',
                                    ),
                                  },
                                  pattern: {
                                    value: IP_PORT_VALIDATION,
                                    message: t(
                                      'remoteDevice.message.validPort',
                                    ),
                                  },
                                },
                              );
                              checkSaveButtonDisable();
                            }}
                            placeholder="8080"
                            className={`${classes.customInputOutline} ${
                              errors.wifi_settings?.[index]?.proxyPort &&
                              classes.customInputOutlineError
                            }`}
                            disabled={!EditAccessForWifi}
                          />
                        )}
                      />
                      {errors.wifi_settings?.[index]?.proxyPort && (
                        <Box className={classes.newWifiErrorCard}>
                          <p
                            style={{
                              padding: '0',
                              margin: '0',
                            }}
                          >
                            {errors.wifi_settings?.[index]?.proxyPort?.message!}
                          </p>
                        </Box>
                      )}
                    </Box>
                    {/* ByPass Proxy */}
                    <Box
                      sx={{
                        position: 'relative',
                        width: '200px',
                      }}
                      className="remove-device-setup-wifi-setting"
                    >
                      <Box sx={{ display: 'flex' }}>
                        <label className={classes.customInputLabel}>
                          {t('remoteDevice.label.bypassProxy')}
                        </label>
                        <Typography className={classes.loginCardLevelMarker}>
                          *
                        </Typography>
                      </Box>
                      <Controller
                        control={control}
                        name={`wifi_settings.${index}.bypassProxy`}
                        defaultValue={field.bypassProxy}
                        rules={{
                          required: {
                            value: true,
                            message: t('common.validationError.required'),
                          },
                          maxLength: {
                            value: 256,
                            message: t('remoteDevice.message.maxChar256'),
                          },
                          pattern: {
                            value: BYPASS_PROXY_VALIDATION,
                            message: t('remoteDevice.message.ValidBypass'),
                          },
                        }}
                        render={({ field: { onChange, value } }) => (
                          <InputBase
                            inputProps={{
                              maxLength: 257,
                            }}
                            value={value}
                            onChange={(e) => {
                              onChange(e.target.value);
                              validateInput(
                                `wifi_settings.${index}.bypassProxy`,
                                e.target.value,
                                {
                                  required: {
                                    value: true,
                                    message: t(
                                      'common.validationError.required',
                                    ),
                                  },
                                  maxLength: {
                                    value: 256,
                                    message: t(
                                      'remoteDevice.message.maxChar256',
                                    ),
                                  },
                                  pattern: {
                                    value: BYPASS_PROXY_VALIDATION,
                                    message: t(
                                      'remoteDevice.message.ValidBypass',
                                    ),
                                  },
                                },
                              );
                              checkSaveButtonDisable();
                            }}
                            placeholder={t('remoteDevice.placeholder.myComp')}
                            className={`${classes.customInputOutline} ${
                              errors.wifi_settings?.[index]?.bypassProxy &&
                              classes.customInputOutlineError
                            } ${'remove-device-setup-custom-placeholder'}`}
                            disabled={!EditAccessForWifi}
                          />
                        )}
                      />
                      {errors.wifi_settings?.[index]?.bypassProxy && (
                        <Box className={classes.newWifiErrorCard}>
                          <p
                            style={{
                              padding: '0',
                              margin: '0',
                            }}
                          >
                            {
                              errors.wifi_settings?.[index]?.bypassProxy
                                ?.message!
                            }
                          </p>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              )}
              {proxyType === 2 && (
                // PacURL
                <Box
                  sx={{
                    position: 'relative',
                    width: '200px',
                    marginTop: '13px',
                  }}
                  className="remove-device-setup-wifi-setting"
                >
                  <Box sx={{ display: 'flex' }}>
                    <label className={classes.customInputLabel}>
                      {t('remoteDevice.label.pacURL')}
                    </label>
                    <Typography className={classes.loginCardLevelMarker}>
                      *
                    </Typography>
                  </Box>
                  <Controller
                    control={control}
                    name={`wifi_settings.${index}.pacUrl`}
                    defaultValue={field.pacUrl}
                    rules={{
                      required: {
                        value: true,
                        message: t('common.validationError.required'),
                      },
                      maxLength: {
                        value: 256,
                        message: t('remoteDevice.message.maxLength'),
                      },
                      pattern: {
                        value: PROXY_NAME_VALIDATION,
                        message: t('remoteDevice.message.validUrl'),
                      },
                    }}
                    render={({ field: { onChange, value } }) => (
                      <InputBase
                        inputProps={{
                          maxLength: 257,
                        }}
                        value={value}
                        onChange={(e) => {
                          onChange(e.target.value);
                          validateInput(
                            `wifi_settings.${index}.pacUrl`,
                            e.target.value,
                            {
                              required: {
                                value: true,
                                message: t('common.validationError.required'),
                              },
                              maxLength: {
                                value: 256,
                                message: t('remoteDevice.message.maxLength'),
                              },
                              pattern: {
                                value: PROXY_NAME_VALIDATION,
                                message: t('remoteDevice.message.validUrl'),
                              },
                            },
                          );
                          checkSaveButtonDisable();
                        }}
                        placeholder={t('remoteDevice.placeholder.proxy')}
                        className={`${classes.customInputOutline} ${
                          errors.wifi_settings?.[index]?.pacUrl &&
                          classes.customInputOutlineError
                        }`}
                        disabled={!EditAccessForWifi}
                      />
                    )}
                  />
                  {errors.wifi_settings?.[index]?.pacUrl && (
                    <Box className={classes.newWifiErrorCard}>
                      <p style={{ padding: '0', margin: '0' }}>
                        {errors.wifi_settings?.[index]?.pacUrl?.message!}
                      </p>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
            {/* proxy section end  */}

            {/* IP Setting */}
            <Box
              className="remove-device-setup-wifi-setting"
              sx={{ width: '200px', marginBottom: '13px' }}
            >
              <label className={classes.customInputLabel}>
                {' '}
                {t('remoteDevice.label.ipSetting')}{' '}
              </label>
              <CommonSelect
                ControllerName={`wifi_settings.${index}.wifiIPSettingType`}
                control={control}
                options={ipAddressOpt}
                isSearchable={false}
                className={classes.reactSelect}
                classNamePrefix="react-select"
                styles={customStyles}
                isDisabled={!EditAccessForWifi}
                onChangeValue={handleWifiIPSettingTypeChange}
              />
              {/* <InputSelect /> */}
            </Box>
            {/* ip settings hidden elements  */}
            {wifiIPSettingType === 1 && (
              <Box>
                <Box
                  sx={{ marginBottom: '13px' }}
                  className={`${classes.remoteDialogInnerWrapper} ${
                    classes.gridCol3
                  } ${'remove-device-setup-grid-col-1'}`}
                >
                  {/* IP Address */}
                  <Box
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                    className="remove-device-setup-wifi-setting"
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.ipAddress')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.ipAddress`}
                      defaultValue={field.ipAddress}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        pattern: {
                          value: IP_ADDRESS_VALIDATION,
                          message: t('remoteDevice.message.invalidIpAdd'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 16,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            validateInput(
                              `wifi_settings.${index}.ipAddress`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                pattern: {
                                  value: IP_ADDRESS_VALIDATION,
                                  message: t(
                                    'remoteDevice.message.invalidIpAdd',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder="192.168.1.128"
                          className={`${classes.customInputOutline} ${
                            errors.wifi_settings?.[index]?.ipAddress &&
                            classes.customInputOutlineError
                          }`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.ipAddress && (
                      <Box className={classes.newWifiErrorCard}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.wifi_settings?.[index]?.ipAddress?.message!}
                        </p>
                      </Box>
                    )}
                  </Box>
                  {/* GateWay */}
                  <Box
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                    className="remove-device-setup-wifi-setting"
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.gateWay')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.gateway`}
                      defaultValue={field.gateway}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        pattern: {
                          value: IP_ADDRESS_VALIDATION,
                          message: t('remoteDevice.message.invalidGatway'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 16,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            validateInput(
                              `wifi_settings.${index}.gateway`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                pattern: {
                                  value: IP_ADDRESS_VALIDATION,
                                  message: t(
                                    'remoteDevice.message.invalidGatway',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder="192.268.1.1"
                          className={`${classes.customInputOutline} ${
                            errors.wifi_settings?.[index]?.gateway &&
                            classes.customInputOutlineError
                          }`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.gateway && (
                      <Box className={classes.newWifiErrorCard}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.wifi_settings?.[index]?.gateway?.message!}
                        </p>
                      </Box>
                    )}
                  </Box>
                  {/* Prefix Length */}
                  <Box
                    sx={{
                      width:
                        accountInfo.locale === LOCALE_CODE.JA
                          ? '207px'
                          : '200px',
                      position: 'relative',
                    }}
                    className="remove-device-setup-wifi-setting"
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.prefixLen')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.networkPrefixLength`}
                      defaultValue={field.networkPrefixLength}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        pattern: {
                          value: NETWORK_PREFIX,
                          message: t('remoteDevice.message.invlalidPrefixLen'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 3,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            validateInput(
                              `wifi_settings.${index}.networkPrefixLength`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                pattern: {
                                  value: NETWORK_PREFIX,
                                  message: t(
                                    'remoteDevice.message.invlalidPrefixLen',
                                  ),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder="24"
                          className={`${classes.customInputOutline} ${
                            classes.customInputOutlineSmRight
                          } ${
                            errors.wifi_settings?.[index]
                              ?.networkPrefixLength &&
                            classes.customInputOutlineError
                          }  ${'remove-device-setup-custom-input'}`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.networkPrefixLength && (
                      <Box className={classes.newWifiErrorCardPrefixLen}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {
                            errors.wifi_settings?.[index]?.networkPrefixLength
                              ?.message!
                          }
                        </p>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{ marginBottom: '15px' }}
                  className={`${classes.remoteDialogInnerWrapper} ${
                    classes.gridCol3
                  } ${'remove-device-setup-grid-col-1'}`}
                >
                  {/* DNS 1 */}
                  <Box
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                    className="remove-device-setup-wifi-setting"
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.dns1')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.dns1`}
                      defaultValue={field.dns1}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        pattern: {
                          value: IP_ADDRESS_VALIDATION,
                          message: t('remoteDevice.message.invalidDNS'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 16,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            validateInput(
                              `wifi_settings.${index}.dns1`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                pattern: {
                                  value: IP_ADDRESS_VALIDATION,
                                  message: t('remoteDevice.message.invalidDNS'),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder="8.8.8.8"
                          className={`${classes.customInputOutline} ${
                            errors.wifi_settings?.[index]?.dns1 &&
                            classes.customInputOutlineError
                          }`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.dns1 && (
                      <Box className={classes.newWifiErrorCard}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.wifi_settings?.[index]?.dns1?.message!}
                        </p>
                      </Box>
                    )}
                  </Box>
                  {/* DNS 2 */}
                  <Box
                    sx={{
                      width: '200px',
                      position: 'relative',
                    }}
                    className="remove-device-setup-wifi-setting"
                  >
                    <Box sx={{ display: 'flex' }}>
                      <label className={classes.customInputLabel}>
                        {t('remoteDevice.label.dns2')}
                      </label>
                      <Typography className={classes.loginCardLevelMarker}>
                        *
                      </Typography>
                    </Box>
                    <Controller
                      control={control}
                      name={`wifi_settings.${index}.dns2`}
                      defaultValue={field.dns2}
                      rules={{
                        required: {
                          value: true,
                          message: t('common.validationError.required'),
                        },
                        pattern: {
                          value: IP_ADDRESS_VALIDATION,
                          message: t('remoteDevice.message.invalidDNS'),
                        },
                      }}
                      render={({ field: { onChange, value } }) => (
                        <InputBase
                          inputProps={{
                            maxLength: 16,
                          }}
                          value={value}
                          onChange={(e) => {
                            onChange(e.target.value);
                            validateInput(
                              `wifi_settings.${index}.dns2`,
                              e.target.value,
                              {
                                required: {
                                  value: true,
                                  message: t('common.validationError.required'),
                                },
                                pattern: {
                                  value: IP_ADDRESS_VALIDATION,
                                  message: t('remoteDevice.message.invalidDNS'),
                                },
                              },
                            );
                            checkSaveButtonDisable();
                          }}
                          placeholder="8.8.4.4"
                          className={`${classes.customInputOutline} ${
                            errors.wifi_settings?.[index]?.dns2 &&
                            classes.customInputOutlineError
                          }`}
                          disabled={!EditAccessForWifi}
                        />
                      )}
                    />
                    {errors.wifi_settings?.[index]?.dns2 && (
                      <Box className={classes.newWifiErrorCard}>
                        <p style={{ padding: '0', margin: '0' }}>
                          {errors.wifi_settings?.[index]?.dns2?.message!}
                        </p>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
            {/* ip settings hidden elements  */}
          </Box>
        )}
        {/* advanced options inputs */}
      </Box>
    </div>
  );
};

export default WifiSettingsCard;
