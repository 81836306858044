import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import { translationsOptionsObject } from '../dashboardConstants';

dayjs.extend(weekday);

export const thisWeekRange = () => {
  const firstDay = dayjs().weekday(0).format('YYYYMMDD');
  const lastDay = dayjs().weekday(6).format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const previousWeekRange = () => {
  const firstDay = dayjs().weekday(-7).format('YYYYMMDD');
  const lastDay = dayjs().weekday(-1).format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const thisMonthRange = () => {
  const firstDay = dayjs().startOf('month').format('YYYYMMDD');
  const lastDay = dayjs().endOf('month').format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const previousMonthRange = () => {
  const firstDay = dayjs()
    .month(dayjs().get('month') - 1)
    .format('YYYYMM01');
  const totalDaysInMonth = dayjs()
    .month(dayjs().get('month') - 1)
    .daysInMonth();
  const lastDay = dayjs()
    .month(dayjs().get('month') - 1)
    .format(`YYYYMM${totalDaysInMonth}`);

  return { firstDay, lastDay };
};

export const thisYearRange = () => {
  const firstDay = dayjs().format('YYYY0101');
  const lastDay = dayjs().format('YYYY1231');

  return { firstDay, lastDay };
};

export const previousYearRange = () => {
  const previousYear = dayjs().year() - 1;
  const firstDay = `${previousYear}0101`;
  const lastDay = `${previousYear}1231`;

  return { firstDay, lastDay };
};

export const all = () => {
  const firstDay = dayjs().format('19700101');
  const lastDay = dayjs().format('YYYYMMDD');

  return { firstDay, lastDay };
};

export const dateRangeCalculator = (val: string) => {
  switch (val) {
    case translationsOptionsObject.ALL:
      return all();
    case translationsOptionsObject.PREVIOUS_WEEK:
      return previousWeekRange();
    case translationsOptionsObject.THIS_WEEK:
      return thisWeekRange();
    case translationsOptionsObject.PREVIOUS_MONTH:
      return previousMonthRange();
    case translationsOptionsObject.THIS_MONTH:
      return thisMonthRange();
    case translationsOptionsObject.PREVIOUS_YEAR:
      return previousYearRange();
    case translationsOptionsObject.THIS_YEAR:
      return thisYearRange();

    default:
      return thisWeekRange();
  }
};
