import { useEffect, useMemo, useState, useCallback } from 'react';
import useSwitchLocaleLanguage from './useSwitchLocaleLanguage';
import {
  API_URL,
  CSV_DOWNLOAD_KEY,
  DATE_FORMAT,
  FEATURE_PERMISSION,
  SERVER_MESSAGE,
} from '../constants/constants';
import callExportLogDataApi from '../apis/callExportLogDataApi';
import useAccountInfo from './useAccountInfo';
import useDateRange, { UseDateRangeValue } from './useDateRange';
// import { createDonwloadLinkAndClick } from '../utils/utility';
import {
  convertStrToDateyyyyMM01T00,
  fetchTodayUTCyyyyMM01T00,
} from '../utils/dateUtil';
import {
  formatDownloadDate,
  validateDownloadDate,
  createDonwloadErrorMessage,
} from '../utils/donwload/downloadDateUtil';
import {
  DownloadDate,
  DOWNLOAD_DATE_ERROR_TYPE,
} from '../types/download/downloadDate';
import useSnackbar from './useSnackbar';

/**
 * 本カスタムフックからの返却値
 */
export type UseLogDataDownloadDialogValue = {
  // ログデータダウンロードダイアログを表示するか否か(true=表示)
  isLogDataDonwloadDialogDisplay: boolean;
  // ログデータダウンロードダイアログを表示
  openLogDataDownloadDialog: () => void;
  // ログデータダウンロードダイアログを非表示
  closeLogDataDownloadDialog: () => void;
  // ダウンロード処理
  onClickDownload: () => void;
  // 日付範囲に関する値
  dateRangeValue: UseDateRangeValue;
  // ダウンロード日付選択に関する値
  downloadDate: DownloadDate;
  // エラータイプ
  alertType: DOWNLOAD_DATE_ERROR_TYPE | null;
  // エラーメッセージ
  errorMessage: string;
  // お知らせポップアップを非表示にする
  onClickCloseNoticeDialog: () => void;
  // お知らせポップアップを表示するか否か
  isNoticeDialogDisplay: boolean;
  isCallingReportApi: boolean;
};

type reponseType = {
  message: string;
};

/**
 * ログデータダウンロードダイアログ hooks
 *
 * @returns
 */
const useLogDataDownloadDialog = (): UseLogDataDownloadDialogValue => {
  // アクセスキー
  // アカウント情報
  const { accountInfo } = useAccountInfo();
  const { displaySnackbar } = useSnackbar();

  // datePicker
  const defaultDate = fetchTodayUTCyyyyMM01T00();
  const { startDate, endDate, changeStartDate, changeEndDate } = useDateRange(
    defaultDate,
    defaultDate,
  );
  // ログデータダウンロードダイアログを表示するか否か(true=表示) get/set
  const [isLogDataDonwloadDialogDisplay, setIsLogDataDonwloadDialogDisplay] =
    useState<boolean>(false);
  const [isCallingReportApi, setIsCallingReportApi] = useState<boolean>(false);

  // お知らせポップアップを表示するか否か
  const [isNoticeDialogDisplay, setIsNoticeDialogDisplay] =
    useState<boolean>(false);

  // アラート状態
  const [alertType, setAlertType] = useState<DOWNLOAD_DATE_ERROR_TYPE | null>(
    null,
  );

  // 言語切り替えhooks(エラーメッセージ返すときに日本語/英語で出し分ける時に使う)
  const { t } = useSwitchLocaleLanguage();

  // Smallest value that can be selected (corporate registration date and time)
  const minDate = useMemo(
    (): Date => convertStrToDateyyyyMM01T00(accountInfo.corpCreatedDt),
    [accountInfo.corpCreatedDt],
  );
  // Maximum value that can be selected (currently month)
  const maxDate = useMemo(
    (): Date => fetchTodayUTCyyyyMM01T00(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLogDataDonwloadDialogDisplay],
  );

  /**
   * エラーメッセージ
   */
  const errorMessage = useMemo((): string => {
    if (!alertType) {
      return '';
    }

    return createDonwloadErrorMessage(
      alertType,
      t,
      DATE_FORMAT,
      minDate,
      maxDate,
    );
  }, [alertType, maxDate, minDate, t]);

  /**
   * ログデータダウンロードダイアログを表示
   */
  const openLogDataDownloadDialog = (): void => {
    if (
      accountInfo.featurePermissions?.REPORTING?.LOGDATA?.includes(
        FEATURE_PERMISSION.REPORTING.LOGDATA.EXPORT,
      )
    ) {
      setIsLogDataDonwloadDialogDisplay(true);
    } else {
      setIsLogDataDonwloadDialogDisplay(false);
      displaySnackbar({
        message: t('common.error.accessDenied'),
        timeout: 3005,
        type: 'error',
      });
    }
  };

  /**
   * ログデータダウンロードダイアログを非表示
   */
  const closeLogDataDownloadDialog = (): void => {
    setIsLogDataDonwloadDialogDisplay(false);
  };

  /**
   * ダイアログの表示非表示の切り替え時にエラーメッセージを非表示にし、
   * minDate、maxDateの設定をリセットする
   */
  useEffect(() => {
    if (!isLogDataDonwloadDialogDisplay) {
      setAlertType(null);
      // Return the date on which the dialog is selected to the initial display
      const initDefaultDate = fetchTodayUTCyyyyMM01T00();
      changeStartDate(initDefaultDate);
      changeEndDate(initDefaultDate);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogDataDonwloadDialogDisplay]);

  /**
   * 日付バリデーションチェック
   *
   * @returns true=OK / false=バリデーションエラー
   */
  const validateDate = useCallback((): boolean => {
    // エラー文言非表示
    setAlertType(null);

    // 入力値バリデーションチェック
    const result: DOWNLOAD_DATE_ERROR_TYPE | null = validateDownloadDate(
      startDate,
      endDate,
      minDate,
      maxDate,
      DATE_FORMAT,
    );
    if (result !== null) {
      // バリデーションエラー
      setAlertType(result);

      return false;
    }

    return true;
  }, [startDate, endDate, minDate, maxDate]);

  /**
   * 期間開始日または期間終了日が変更された時点で、
   * 日付バリデーションチェック実行
   */
  useEffect(() => {
    validateDate();
  }, [validateDate]);

  /**
   * ログデータダウンロード処理
   */
  const onClickDownload = () => {
    setIsCallingReportApi(true);
    // 期間が変更された時点で日付バリエーションチェックが行われているが、
    // フォーカス外れるとエラーが表示されたままダウンロード可能なのでここでもチェックする
    if (!validateDate()) {
      return;
    }

    // すでに上でバリデーションチェックを行っているためnullになることはありえないが、Date型を確定させるために処理入れる
    if (startDate == null || endDate == null) {
      return;
    }
    // ドメイン

    void callExportLogDataApi({
      corpId: accountInfo.corpId,
      from: formatDownloadDate(startDate)?.slice(0, 6),
      to: formatDownloadDate(endDate)?.slice(0, 6),
    })
      .then((response: any) => {
        if (response === SERVER_MESSAGE.NO_INTERNET) {
          return;
        }
        const responseFail = response as reponseType;
        if (responseFail?.message === SERVER_MESSAGE.ACCESS_PERMISSION_DENIED) {
          displaySnackbar({
            message: t('logDataDonwloadApiError.failed'),
            timeout: 3005,
            type: 'error',
          });
        }
        if (responseFail?.message) {
          setIsNoticeDialogDisplay(false);
          closeLogDataDownloadDialog();

          return;
        }
        const binaryData = [];
        binaryData.push(response);

        const url = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'application/zip' }),
        );
        const link = document.createElement('a');
        link.href = url;
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        link.setAttribute('download', 'LogData.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsNoticeDialogDisplay(false);
        closeLogDataDownloadDialog();
        displaySnackbar({
          message: t('logDataDonwloadApiError.success'),
          timeout: 3005,
        });
      })
      .catch((error) => {
        closeLogDataDownloadDialog();
        setIsNoticeDialogDisplay(false);
        displaySnackbar({
          message: t('common.error.serverErr'),
          timeout: 3005,
          type: 'error',
        });
        setIsCallingReportApi(false);
        console.log(error, 'download error');
      })
      .finally(() => setIsCallingReportApi(false));
  };

  /**
   * お知らせポップアップを非表示にする
   */
  const onClickCloseNoticeDialog = () => {
    setIsNoticeDialogDisplay(false);
    closeLogDataDownloadDialog();
  };

  return {
    isLogDataDonwloadDialogDisplay,
    openLogDataDownloadDialog,
    closeLogDataDownloadDialog,
    onClickDownload,
    dateRangeValue: {
      startDate,
      endDate,
      changeStartDate,
      changeEndDate,
    },
    downloadDate: {
      minDate,
      maxDate,
    },
    alertType,
    errorMessage,
    onClickCloseNoticeDialog,
    isNoticeDialogDisplay,
    isCallingReportApi,
  };
};

export default useLogDataDownloadDialog;
