import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import storageSession from 'redux-persist/lib/storage/session';
import { accessKeySlice } from './slice/accessKeySlice';
import { accountInfoSlice } from './slice/accountInfoSlice';
import { pageRestoreStateSlice } from './slice/pageRestoreStateSlice';
import { deviceDetailUpdateStateSlice } from './slice/deviceDetailUpdateStateSlice';
import { DrawerReducer } from './slice/drawerStateSlice';
import { SnackbarReducer } from './slice/snackbarSlice';
import { RedirectDialogReducer } from './slice/redirectDialog';
import { WelcomeDialogReducer } from './slice/welcomeDialog';
import { WEB_STORAGE } from '../constants/constants';
import { GroupListReducer } from './slice/groupListSlice';
import { DeviceListReducer } from './slice/translationCountSlice';

const persistConfig = {
  key: 'root', // storeに保存されるkey名
  storage, // session storageに保存
  whitelist: [WEB_STORAGE.IOT_ACCESS_KEY, WEB_STORAGE.ACCOUNT_INFO], // storageには`iotaccesskey`と`accountInfo`のみ保存
  version: 1, // マイグレーション用に指定しておいた方が良い
  debug: true,
};

const reducers = combineReducers({
  [WEB_STORAGE.IOT_ACCESS_KEY]: accessKeySlice.reducer, // whitelistに指定したkey名と同じ値
  [WEB_STORAGE.ACCOUNT_INFO]: accountInfoSlice.reducer,
  [WEB_STORAGE.PAGE_RESTORE_STATE]: pageRestoreStateSlice.reducer,
  [WEB_STORAGE.DEVICE_DETAIL_UPDATE_STATE]:
    deviceDetailUpdateStateSlice.reducer,
  [WEB_STORAGE.SNACKBAR_STATE]: SnackbarReducer,
  [WEB_STORAGE.REDIRECT_DIALOG_STATE]: RedirectDialogReducer,
  [WEB_STORAGE.WELCOME_DIALOG_STATE]: WelcomeDialogReducer,
  [WEB_STORAGE.GROUP_LIST_STATE]: GroupListReducer,
  [WEB_STORAGE.DEVICE_STATE]: DeviceListReducer,
  [WEB_STORAGE.DRAWER_STATE]: DrawerReducer,
});

// 永続化設定されたReducerとして定義
const persistedReducer = persistReducer(persistConfig, reducers);

// https://github.com/rt2zz/redux-persist/issues/988
// こちらを参考に「persist」キーの「serializable」のチェックを無視
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV === 'development',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ['persist/PERSIST'],
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = (): AppDispatch => useDispatch<AppDispatch>();

export default store;
