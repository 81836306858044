/* eslint-disable @typescript-eslint/no-unsafe-return */

 const customStylesMd = {
    control: (provided: any, state: { isFocused: any }) => ({
      ...provided,
      background: '#fff',
      minHeight: '38px',
      height: '38px',
      borderRadius: '6px',
      boxShadow: state.isFocused ? '0px 0px 1px #939598' : null,
      border: state.isFocused ? '1px solid #939598' : '1px solid #d9d9d9',
      fontSize: '14px',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#939598',
      },
      '&:focus': {
        borderColor: '#939598',
      },
    }),

    valueContainer: (provided: any) => ({
      ...provided,
      height: '38px',
      padding: '0 8px',
      marginTop: '-1px',
    }),

    input: (provided: any) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided: any) => ({
      ...provided,
      height: '38px',
      marginTop: '-1px',
    }),
    menu: (provided: any) => ({
      ...provided,
      borderRadius: '6px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      height: '37px',
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      color: '#313133',
      ':active': {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        backgroundColor: state.isSelected ? '#EAEAEB' : '#fff',
      },
      ':first-child': {
        borderTopRightRadius: '6px',
        borderTopLeftRadius: '6px',
      },
      ':last-child': {
        borderBottomRightRadius: '6px',
        borderBottomLeftRadius: '6px',
      },
    }),
  };

  export default customStylesMd;